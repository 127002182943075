<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <template v-if="internetConnection">
            <loader-page v-if="isLoading"></loader-page>
            <template v-else>
                <!-- Company detail -->
                <div v-if="company" class="uk-card uk-card-default uk-padding-small">
                    <div class="uk-flex uk-flex-middle">
                        <p class="uk-heading-small uk-margin-remove">{{ company.name || '-' }}</p>
                        <p class="label uk-margin-left uk-margin-remove-top uk-margin-remove-bottom uk-margin-remove-right" :style="statusColor({ status: company.status })">
                            {{ statusLabel({ status: company.status }) }}
                        </p>
                    </div>
                    <div class="uk-margin-top">
                        <p class="uk-text-large uk-text-meta uk-margin-remove">{{ company.description || '-' }}</p>
                    </div>
                    <div class="uk-margin-small-top uk-text-meta uk-text-small">
                        <span>PIC BDs: </span>
                        <span v-if="company.pic_bds.length > 0">{{ picLabel({ datas: company.pic_bds }) }}</span>
                        <span v-else>-</span>
                    </div>
                    <div class="uk-margin-small-top uk-text-meta uk-text-small">
                        <span>PIC Ops: </span>
                        <span v-if="company.pic_ops.length > 0">{{ picLabel({ datas: company.pic_ops }) }}</span>
                        <span v-else>-</span>
                    </div>
                </div>
                <!-- End company detail -->

                <!-- Action -->
                <div v-if="company" class="uk-margin-top uk-grid-small" uk-grid>
                    <div
                        v-if="consist(user_cred.roles, ['admin-bd','qwe123','superadmin-product']) && (company.status == 2 || company.status == 3 || company.status == 4)"
                        class="uk-width-1-4"
                    >
                        <button
                            type="button"
                            class="uk-button uk-button-primary uk-width-1-1"
                            @click="showCreateJobTitleModalBox({ actionType: 'createJobTitle', id: null, internarShipper: false, status: null, name: '' })"
                        >
                            <span>1. Create Job Title</span>
                        </button>
                    </div>
                    <div class="uk-width-1-4">
                        <button type="button" class="uk-button uk-button-default uk-width-1-1" @click="officeType({ companyId: company._id })">
                            <span class="uk-margin-small-right" uk-icon="icon: home"></span>
                            <span v-if="consist(user_cred.roles, ['admin-bd','qwe123','superadmin-product']) && (company.status == 2 || company.status == 3 || company.status == 4)">
                                2. Office Type
                            </span>
                            <span v-else>Office Type</span>
                        </button>
                    </div>
                    <div
                        v-if="consist(user_cred.roles, ['admin-bd','qwe123','superadmin-product']) && (company.status == 2 || company.status == 3 || company.status == 4)"
                        class="uk-width-1-4"
                    >
                        <button
                            type="button"
                            class="uk-button uk-button-primary uk-width-1-1"
                            @click="showCreateOrEditOfficeModalBox({
                                actionType: 'createCompanyOffice',
                                id: null,
                                companyId: company._id,
                                type: null,
                                name: null,
                                code: null,
                                domicileCityId: null,
                                address: null,
                                longitude: 0,
                                latitude: 0,
                                radius: null,
                                notes: null,
                                status: null
                            })"
                        >3. Create Office</button>
                    </div>
                    <div class="uk-width-1-4">
                        <button type="button" class="uk-button uk-button-default uk-width-1-1" @click="workersRequest({ companyId: company._id })">
                            <span class="uk-margin-small-right" uk-icon="icon: users"></span>
                            <span v-if="consist(user_cred.roles, ['admin-bd','qwe123','superadmin-product']) && (company.status == 2 || company.status == 3 || company.status == 4)">
                                4. Workers Request
                            </span>
                            <span v-else>Workers Request</span>
                        </button>
                    </div>
                    <div class="uk-width-1-4">
                        <button
                            type="button"
                            class="uk-button uk-button-default uk-width-1-1 myrobin-primary-button-filled uk-flex uk-flex-center uk-flex-middle"
                            @click="showDownloadTemplateModalBox"
                        >
                            <img :src="`${images}/download-outline-white.svg`" alt="Download icon" loading="lazy" />
                            <span class="uk-margin-small-left">Download template</span>
                        </button>
                    </div>
                    <div class="uk-width-1-4">
                        <button
                            type="button"
                            class="uk-button uk-button-default uk-width-1-1 myrobin-primary-button-outlined uk-flex uk-flex-center uk-flex-middle"
                            @click="showUploadTemplateModalBox"
                        >
                            <img :src="`${images}/upload-outline-primary.svg`" alt="Upload icon" loading="lazy" />
                            <span class="uk-margin-small-left">Upload template</span>
                        </button>
                    </div>
                    <div class="uk-width-1-4">
                        <button
                            type="button"
                            class="uk-button uk-button-default uk-width-1-1 myrobin-warning-button-filled uk-flex uk-flex-center uk-flex-middle"
                            @click="showErrorLogModalBox"
                        >
                            <img :src="`${images}/history-outline-white.svg`" alt="History icon" loading="lazy" />
                            <span class="uk-margin-small-left">Error log</span>
                        </button>
                    </div>
                    <div 
                        v-if="consist(user_cred.roles, ['admin-bd','qwe123','superadmin-product']) && (company.status == 2 || company.status == 3 || company.status == 4)"
                        class="uk-width-1-4"
                    >
                        <button 
                            type="button" 
                            class="uk-button uk-button-default uk-width-1-1"
                            @click="showSettingModalBox()"
                        >
                            <span class="uk-margin-small-right" uk-icon="icon: settings"></span>
                            <span>Settings</span>
                        </button>
                    </div>
                </div>
                <!-- End action -->

                <!-- Table list -->
                <div v-if="company" class="uk-margin-top uk-grid-small" uk-grid>
                    <!-- Job title -->
                    <div class="uk-width-1-4">
                        <p v-if="consist(user_cred.roles, ['admin-bd','qwe123','superadmin-product']) && (company.status == 2 || company.status == 3 || company.status == 4)" class="uk-text-center">
                            1. Job Title
                        </p>
                        <p v-else class="uk-text-center">Job Title</p>
                    </div>
                    <!-- End job title -->

                    <!-- Company office -->
                    <div class="uk-width-3-4">
                        <p v-if="consist(user_cred.roles, ['admin-bd','qwe123','superadmin-product']) && (company.status == 2 || company.status == 3 || company.status == 4)" class="uk-text-center">
                            3. Company Office (Property)
                        </p>
                        <p v-else class="uk-text-center">Company Office (Property)</p>
                    </div>
                    <!-- End company office -->

                    <!-- Job title table -->
                    <div class="uk-width-1-4">
                        <div class="uk-card uk-card-default uk-width-1-1">
                            <div class="uk-overflow-auto">
                                <table class="uk-table uk-table-small uk-table-middle uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th class="uk-text-center uk-text-nowrap">Job Name</th>
                                            <th class="uk-text-center uk-text-nowrap">Job status</th>
                                            <th
                                                v-if="consist(user_cred.roles, ['admin-bd','qwe123'])"
                                                class="uk-text-center uk-text-nowrap uk-width-small"
                                            >Action</th>
                                        </tr>
                                    </thead>
                                    <loading v-if="jobTitleTableLoading" :colspan="consist(user_cred.roles, ['admin-bd','qwe123']) ? 3 : 2"></loading>
                                    <template v-else>
                                        <tbody v-if="company.roles.length > 0">
                                            <tr
                                                v-for="(role, index) in company.roles"
                                                :key="index"
                                            >
                                                <td class="uk-text-center uk-text-nowrap">{{ preview({ string: role.name }) }}</td>
                                                <td class="uk-text-center uk-text-nowrap">
                                                    <div
                                                        class="label"
                                                        :style="`background-color: ${role.status ? '#00cc00' : '#e63c3c'}`"
                                                    >{{ role.status ? 'Active' : 'Non Active' }}</div>
                                                </td>
                                                <td
                                                    v-if="consist(user_cred.roles, ['admin-bd','qwe123'])"
                                                    class="uk-text-center uk-text-nowrap uk-width-small"
                                                >
                                                    <button type="button" class="uk-button uk-button-default uk-button-small">Actions</button>
                                                    <div uk-dropdown="mode: click">
                                                        <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                                            <li>
                                                                <a
                                                                    href="#"
                                                                    v-clipboard:copy="role._id"
                                                                    v-clipboard:success="onCopy"
                                                                >Copy ID</a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="#"
                                                                    @click="showCreateJobTitleModalBox({
                                                                        actionType: 'editJobTitle',
                                                                        id: role._id,
                                                                        internarShipper: role.internal_shipper,
                                                                        status: role.status,
                                                                        name: role.name
                                                                    })"
                                                                >Edit</a>
                                                            </li>
                                                            <li>
                                                                <a
                                                                    href="#"
                                                                    @click="showDeleteJobTitleModalBox({
                                                                        actionType: 'deleteJobTitle',
                                                                        id: role._id,
                                                                        internarShipper: role.internal_shipper,
                                                                        status: role.status,
                                                                        name: role.name
                                                                    })"
                                                                >Delete</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <empty-table v-else :colspan="consist(user_cred.roles, ['admin-bd','qwe123']) ? 3 : 2"></empty-table>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- End job title table -->

                    <!-- Company office table -->
                    <div class="uk-width-3-4">
                        <div class="uk-card uk-card-default uk-width-1-1">
                            <div class="uk-overflow-auto">
                                <table class="uk-table uk-table-small uk-tablle-middle uk-table-divider">
                                    <thead>
                                        <tr>
                                            <th class="uk-text-center uk-text-nowrap">Name</th>
                                            <th class="uk-text-center uk-text-nowrap">Code</th>
                                            <th class="uk-text-center uk-text-nowrap">City</th>
                                            <th class="uk-text-center uk-text-nowrap">Address</th>
                                            <th class="uk-text-center uk-text-nowrap">Notes</th>
                                            <th class="uk-text-center uk-text-nowrap">Status</th>
                                            <th class="uk-text-center uk-text-nowrap uk-width-small">Action</th>
                                        </tr>
                                    </thead>
                                    <loading v-if="companyOfficeTableLoading" :colspan="7"></loading>
                                    <template v-else>
                                        <tbody v-if="company.offices.length > 0">
                                            <tr
                                                v-for="(office, index) in company.offices"
                                                :key="index"
                                            >
                                                <td class="uk-text-center uk-text-nowrap">{{ preview({ string: office.name }) }}</td>
                                                <td class="uk-text-center uk-text-nowrap">{{ preview({ string: office.code }) }}</td>
                                                <td class="uk-text-center uk-text-nowrap">{{ office.domicile_city_id && office.domicile_city_id.name ? office.domicile_city_id.name : '-' }}</td>
                                                <td class="uk-text-center uk-text-nowrap">{{ preview({ string: office.address }) }}</td>
                                                <td class="uk-text-center uk-text-nowrap">{{ preview({ string: office.notes }) }}</td>
                                                <td class="uk-text-center uk-text-nowrap">
                                                    <div class="label uk-margin-auto" :style="`background-color: ${office.status ? '#00cc00' : '#e63c3c'}`">
                                                        {{ office.status ? 'Active' : 'Non Active' }}
                                                    </div>
                                                </td>
                                                <td class="uk-text-center uk-text-nowrap uk-width-small">
                                                    <button type="button" class="uk-button uk-button-default uk-button-small">Actions</button>
                                                    <div uk-dropdown="mode: click">
                                                        <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                                            <li><a @click="roleSalaryCompany({ companyId: company._id, officeId: office._id })">Salary Setup</a></li>
                                                            <li><a @click="assignMitra({ companyId: company._id, officeId: office._id })">Assign Mitra</a></li>
                                                            <template v-if="consist(user_cred.roles, ['admin-bd','qwe123'])">
                                                                <li class="uk-nav-divider"></li>
                                                                <li><a href="#" @click="showJobListModalBox({ officeId: office._id })">Generate QR Code</a></li>
                                                                <li><a href="#" @click="showCompanyOfficeLogModalBox({ officeId: office._id })">Show Logs</a></li>
                                                                <li>
                                                                    <a
                                                                        href="#"
                                                                        v-clipboard:copy="office._id"
                                                                        v-clipboard:success="onCopy"
                                                                    >Copy ID</a>
                                                                </li>
                                                                <li>
                                                                    <a
                                                                        href="#"
                                                                        @click="showCreateOrEditOfficeModalBox({
                                                                            actionType: 'editCompanyOffice',
                                                                            id: office._id,
                                                                            companyId: office.company_id,
                                                                            type: office.type_id,
                                                                            name: office.name,
                                                                            code: office.code,
                                                                            domicileCityId: office.domicile_city_id,
                                                                            address: office.address,
                                                                            longitude: office.geoloc && office.geoloc.coordinates[0] ? office.geoloc.coordinates[0] : 0,
                                                                            latitude: office.geoloc && office.geoloc.coordinates[1] ? office.geoloc.coordinates[1] : 0,
                                                                            radius: office.radius,
                                                                            notes: office.notes,
                                                                            status: office.status
                                                                        })"
                                                                    >Edit</a>
                                                                </li>
                                                                <li>
                                                                    <a
                                                                        href="#"
                                                                        @click="showDeleteCompanyOfficeModalBox({
                                                                            id: office._id,
                                                                            type: office.type_id,
                                                                            name: office.name,
                                                                            code: office.code,
                                                                            domicileCityId: office.domicile_city_id,
                                                                            address: office.address,
                                                                            longitude: office.geoloc && office.geoloc.coordinates[0] ? office.geoloc.coordinates[0] : 0,
                                                                            latitude: office.geoloc && office.geoloc.coordinates[1] ? office.geoloc.coordinates[1] : 0,
                                                                            radius: office.radius,
                                                                            notes: office.notes,
                                                                            status: office.status
                                                                        })"
                                                                    >Delete</a>
                                                                </li>
                                                            </template>
                                                            <li class="uk-nav-divider"></li>
                                                            <li><a @click="attendanceCompany({ companyId: company._id, officeId: office._id })">Attendance</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <empty-table v-else :colspan="7"></empty-table>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- End company office table -->
                </div>
                <!-- End table list -->

                <!-- Create or edit job title modal box -->
                <div v-if="company" id="create-or-edit-job-title-modal-box" class="uk-flex-top create-or-edit-job-title-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" @click="resetSelectedJobTitle" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Job Title Form</h2>
                        </div>
                        <form @submit.prevent="createOrEditJobTitle">
                            <div class="uk-padding-small uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="company" class="uk-form-label">Company <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="company"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        disabled
                                        v-model="company.name"
                                    >
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="jobName" class="uk-form-label">Job Name <span class="uk-text-danger">*</span></label>
                                    <input
                                        @blur="cleanWhiteSpace"
                                        id="jobName"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        placeholder="Warehouse Manager"
                                        v-model="selectedJobTitle.name"
                                        data-vv-name="role_name"
                                        data-vv-scope="selectedJobTitle"
                                        v-validate="{
                                            required: true
                                        }"
                                    >
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedJobTitle.role_name')">{{ errors.first('selectedJobTitle.role_name') }}</span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="jobStatus" class="uk-form-label">Job Status <span class="uk-text-danger">*</span></label>
                                    <multiselect
                                        id="jobStatus"
                                        placeholder="Select Status"
                                        label="name"
                                        track-by="name"
                                        :allow-empty="false"
                                        :options="statusOptions"
                                        v-model="selectedJobTitle.status"
                                        data-vv-name="role_status"
                                        data-vv-scope="selectedJobTitle"
                                        v-validate="{
                                            required: true
                                        }"
                                    ></multiselect>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedJobTitle.role_status')">{{ errors.first('selectedJobTitle.role_status') }}</span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="internalShipper">
                                        <input
                                            id="internalShipper"
                                            class="uk-checkbox"
                                            type="checkbox"
                                            v-model="selectedJobTitle.internal_shipper"
                                        >
                                        <span> Internal Role</span>
                                    </label>
                                </div>
                            </div>
                            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                                <button
                                    v-if="modalBoxButtonLoading"
                                    type="button"
                                    class="uk-button uk-button-default"
                                    disabled
                                >
                                    <span uk-spinner="ratio: 0.5"></span>
                                    <span class="uk-margin-small-left">Loading</span>
                                </button>
                                <button
                                    v-else
                                    type="submit"
                                    class="uk-button"
                                    :class="[disabledFormJobTitleButtonSave ? 'uk-button-default' : 'uk-button-primary']"
                                    :disabled="disabledFormJobTitleButtonSave"
                                >Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- End create or edit job title modal box -->

                <!-- Create or edit office modal box -->
                <div v-if="company" id="create-or-edit-office-modal-box" class="uk-flex-top create-or-edit-office-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" @click="resetSelectedOffice" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Office Form</h2>
                        </div>
                        <form @submit.prevent="createOrEditCompanyOffice">
                            <div class="uk-padding-small uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="company" class="uk-form-label">Company <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="company"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        disabled
                                        v-model="company.name"
                                    >
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="officeType" class="uk-form-label">Office Type <span class="uk-text-danger">*</span></label>
                                    <multiselect
                                        id="officeType"
                                        placeholder="Type to search office type code"
                                        label="name"
                                        track-by="name"
                                        :allow-empty="false"
                                        :searchable="true"
                                        :internal-search="true"
                                        :options-limit="50"
                                        :options="officeTypeOptions"
                                        v-model="selectedOffice.type_id"
                                        data-vv-name="property_type"
                                        data-vv-scope="selectedOffice"
                                        v-validate="{
                                            required: true
                                        }"
                                    ></multiselect>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedOffice.property_type')">{{ errors.first('selectedOffice.property_type') }}</span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="officeName" class="uk-form-label">Office Name <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="officeName"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        placeholder="Warehouse Sparepart"
                                        v-model="selectedOffice.name"
                                        data-vv-name="property_name"
                                        data-vv-scope="selectedOffice"
                                        v-validate="{
                                            required: true
                                        }"
                                    >
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedOffice.property_name')">{{ errors.first('selectedOffice.property_name') }}</span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="officeCode" class="uk-form-label">Office Code <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="officeCode"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        placeholder="WS"
                                        v-model="selectedOffice.code"
                                        data-vv-name="property_code"
                                        data-vv-scope="selectedOffice"
                                        v-validate="{
                                            required: true
                                        }"
                                    >
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedOffice.property_code')">{{ errors.first('selectedOffice.property_code') }}</span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="officeCity" class="uk-form-label">Office City <span class="uk-text-danger">*</span></label>
                                    <multiselect
                                        id="officeCity"
                                        placeholder="Select City"
                                        label="name"
                                        track-by="name"
                                        :allow-empty="false"
                                        :searchable="true"
                                        :internal-search="true"
                                        :options-limit="50"
                                        :options="domicileOptions"
                                        v-model="selectedOffice.domicile_city_id"
                                        data-vv-name="property_city"
                                        data-vv-scope="selectedOffice"
                                        v-validate="{
                                            required: true
                                        }"
                                    ></multiselect>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedOffice.property_city')">{{ errors.first('selectedOffice.property_city') }}</span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="officeAddress" class="uk-form-label">Office Address <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="officeAddress"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        placeholder="Jl. Rasuna Said 14"
                                        v-model="selectedOffice.address"
                                        data-vv-name="property_address"
                                        data-vv-scope="selectedOffice"
                                        v-validate="{
                                            required: true
                                        }"
                                    >
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedOffice.property_address')">{{ errors.first('selectedOffice.property_address') }}</span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="officeLatitude" class="uk-form-label">Coordinate Latitude</label>
                                    <input
                                        id="officeLatitude"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        placeholder="Koordinat lintang antara -90 dan 90"
                                        v-model="selectedOffice.geoloc.lat"
                                        data-vv-name="property_latitude"
                                        data-vv-scope="selectedOffice"
                                        v-validate="{
                                            decimal: true
                                        }"
                                    >
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedOffice.property_latitude')">{{ errors.first('selectedOffice.property_latitude') }}</span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="officeLongitude" class="uk-form-label">Coordinate Longitude</label>
                                    <input
                                        id="officeLongitude"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        placeholder="Koordinat bujur antara -180 and 180"
                                        v-model="selectedOffice.geoloc.lng"
                                        data-vv-name="property_longitude"
                                        data-vv-scope="selectedOffice"
                                        v-validate="{
                                            decimal: true
                                        }"
                                    >
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedOffice.property_longitude')">{{ errors.first('selectedOffice.property_longitude') }}</span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="attendanceRadius" class="uk-form-label">Attendance Radius <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="attendanceRadius"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        placeholder="30"
                                        v-model="selectedOffice.radius"
                                        data-vv-name="property_radius"
                                        data-vv-scope="selectedOffice"
                                        v-validate="{
                                            required: true,
                                            numeric: true,
                                            min: 0
                                        }"
                                    >
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedOffice.property_radius')">{{ errors.first('selectedOffice.property_radius') }}</span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="officeNotes" class="uk-form-label">Office Notes <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="officeNotes"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        placeholder="Notes"
                                        v-model="selectedOffice.notes"
                                        data-vv-name="property_notes"
                                        data-vv-scope="selectedOffice"
                                        v-validate="{
                                            required: true
                                        }"
                                    >
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedOffice.property_notes')">{{ errors.first('selectedOffice.property_notes') }}</span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="officeStatus" class="uk-form-label">Office Status <span class="uk-text-danger">*</span></label>
                                    <multiselect
                                        id="officeStatus"
                                        placeholder="Select Status"
                                        label="name"
                                        track-by="name"
                                        :allow-empty="false"
                                        :options="statusOptions"
                                        v-model="selectedOffice.status"
                                        data-vv-name="property_status"
                                        data-vv-scope="selectedOffice"
                                        v-validate="{
                                            required: true
                                        }"
                                    ></multiselect>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedOffice.property_status')">{{ errors.first('selectedOffice.property_status') }}</span>
                                </div>
                            </div>
                            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                                <button
                                    v-if="modalBoxButtonLoading"
                                    type="button"
                                    class="uk-button uk-button-default"
                                    disabled
                                >
                                    <span uk-spinner="ratio: 0.5"></span>
                                    <span class="uk-margin-small-left">Loading</span>
                                </button>
                                <button
                                    v-else
                                    type="submit"
                                    class="uk-button"
                                    :class="[disabledCreateOrEditCompanyOfficeButton ? 'uk-button-default' : 'uk-button-primary']"
                                    :disabled="disabledCreateOrEditCompanyOfficeButton"
                                >Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- End create or edit office modal box -->

                <!-- Delete job title modal box -->
                <div v-if="company" id="delete-job-title-modal-box" class="uk-flex-top delete-job-title-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" @click="resetSelectedJobTitle" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Delete Company Role?</h2>
                        </div>
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Company Name:</h4>
                                <p class="uk-margin-remove">{{ company.name || '-' }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Role Name:</h4>
                                <p class="uk-margin-remove">{{ selectedJobTitle.name || '-' }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Status:</h4>
                                <p class="uk-margin-remove">
                                    {{ selectedJobTitle.status && selectedJobTitle.status.name ? selectedJobTitle.status.name : '-' }}
                                </p>
                            </div>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Internal Role:</h4>
                                <p class="uk-margin-remove">{{ selectedJobTitle.internal_shipper ? 'Yes' : 'No' }}</p>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="modalBoxButtonLoading"
                                type="button"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button
                                v-else
                                type="button"
                                class="uk-button"
                                :class="[disabledDeleteJobTitleButton ? 'uk-button-default' : 'uk-button-danger']"
                                :disabled="disabledDeleteJobTitleButton"
                                @click="doDeleteJobTitle"
                            >Delete</button>
                        </div>
                    </div>
                </div>
                <!-- End delete job title modal box -->

                <!-- Job title list modal box -->
                <div v-if="company" id="job-title-list-modal-box" class="uk-flex-top job-title-list-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" @click="selectedOffice.id = null; selectedJobRole = null; resetQrCode()" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Generate QR Code</h2>
                        </div>
                        <div v-if="modalBoxLoading" class="uk-modal-body uk-height-medium uk-flex uk-flex-middle uk-flex-center">
                            <span uk-spinner="ratio: 2"></span>
                        </div>
                        <form @submit.prevent="showQrCodeModalBox" v-else>
                            <div class="uk-padding-small uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="jobTitle" class="uk-form-label">Job Title</label>
                                    <multiselect
                                        id="jobTitle"
                                        placeholder="Select Job"
                                        label="name"
                                        track-by="_id"
                                        :allow-empty="false"
                                        :options="jobListOptions"
                                        deselect-label="Can't remove this value"
                                        v-model="selectedJobRole"
                                    ></multiselect>
                                </div>
                            </div>
                            <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                                <button
                                    type="submit"
                                    class="uk-button"
                                    :class="[disabledGenerateQrCodeButton ? 'uk-button-default' : 'uk-button-primary']"
                                    :disabled="disabledGenerateQrCodeButton"
                                >Show QR Code</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- End job title list modal box -->

                <!-- QR code modal box -->
                <div v-if="company" id="qr-code-modal-box" class="uk-flex-top qr-code-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" @click="selectedOffice.id = null; selectedJobRole = null; resetQrCode()" uk-close></button>
                        <div class="uk-modal-body uk-flex uk-flex-center uk-flex-column uk-text-center">
                            <template v-if="filledQr">
                                <h4>{{ company.name || '-' }}</h4>
                                <vue-qrcode
                                    style="padding: 1px; border: 1px solid #ccc; border-radius: 25px; margin: auto; display: block;"
                                    :value="`company_id=${qrCode.company_id}&warehouse_id=${qrCode.warehouse_id}&role_id=${qrCode.role_id}`"
                                    :options='{ width: 200, color: { dark: "#000000", light: "#FFFFFF"} }'
                                ></vue-qrcode>
                                <div class="uk-margin-top uk-text-bold">
                                    <p class="uk-margin-remove">{{ company.address || '-' }}</p>
                                    <p class="uk-margin-remove">{{ qrCode.title || '-' }}</p>
                                </div>
                            </template>
                            <template v-else>
                                <p>Pekerjaan ini tidak menggunakan metode QR absensi.</p>
                            </template>
                        </div>
                    </div>
                </div>
                <!-- End QR code modal box -->

                <!-- Company office log modal box -->
                <div v-if="company" id="company-office-log-modal-box" class="uk-flex-top company-office-log-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" @click="hideCompanyOfficeLogModalBox" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Detail Logs</h2>
                        </div>
                        <div v-if="modalBoxLoading" class="uk-modal-body uk-height-medium uk-flex uk-flex-middle uk-flex-center">
                            <span uk-spinner="ratio: 2"></span>
                        </div>
                        <template v-else>
                            <div v-if="office_logs.docs.length > 0" class="uk-padding-small uk-grid-small" uk-grid>
                                <div
                                    v-for="(log, index) in office_logs.docs"
                                    :key="index"
                                    class="uk-width-1-1"
                                >
                                    <div class="uk-flex uk-flex-left uk-flex-center uk-flex-column uk-text-left">
                                        <template v-if="!log.data_before">
                                            <h4 class="uk-margin-remove">
                                                {{ formatDatetime({ date: log.updated_at }) }} - By: {{ log.user_id && log.user_id.fullname ? log.user_id.fullname : '-' }}
                                            </h4>
                                            <p class="uk-margin-remove">Office just created!</p>
                                        </template>
                                        <template v-else>
                                            <h4 class="uk-margin-remove">
                                                {{ formatDatetime({ date: log.updated_at }) }} - By: {{ log.user_id && log.user_id.fullname ? log.user_id.fullname : '-' }}
                                            </h4>
                                            <template v-if="log.change.length > 0">
                                                <template v-for="(item, x) in log.change">
                                                    <div :key="x" v-if="item == 'status'">
                                                        <span class="uk-text-bold">{{ item }}: </span>
                                                        <span>{{ stateLabel({ state: log.data_before[item] }) }} >> {{ stateLabel({ state: log.data_after[item] }) }}</span>
                                                    </div>
                                                    <div :key="x" v-else-if="item == 'type_id'">
                                                        <span class="uk-text-bold">type: </span>
                                                        <span>{{ typeLabel({ id: log.data_before[item] }) }} >> {{ typeLabel({ id: log.data_after[item] }) }}</span>
                                                    </div>
                                                    <div :key="x" v-else-if="item == 'domicile_city_id'">
                                                        <span class="uk-text-bold">city: </span>
                                                        <span>{{ domicileLabel({ id: log.data_before[item] }) }} >> {{ domicileLabel({ id: log.data_after[item] }) }}</span>
                                                    </div>
                                                    <div :key="x" v-else-if="item == 'longitude'">
                                                        <span class="uk-text-bold">{{ item }}: </span>
                                                        <span>{{ log.data_before.geoloc['coordinates'][0] || '-' }} >> {{ log.data_after.geoloc['coordinates'][0] || '-' }}</span>
                                                    </div>
                                                    <div :key="x" v-else-if="item == 'latitude'">
                                                        <span class="uk-text-bold">{{ item }}: </span>
                                                        <span>{{ log.data_before.geoloc['coordinates'][1] || '-' }} >> {{ log.data_after.geoloc['coordinates'][1] || '-' }}</span>
                                                    </div>
                                                    <div :key="x" v-else>
                                                        <span class="uk-text-bold">{{ item }}: </span>
                                                        <span>{{ log.data_before[item] || '-' }} >> {{ log.data_after[item] || '-' }}</span>
                                                    </div>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <p class="uk-margin-remove">-</p>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="uk-padding-small">
                                <h4 class="uk-margin-top">This office never been updated!</h4>
                            </div>
                        </template>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button type="button" class="uk-button uk-button-primary" @click="hideCompanyOfficeLogModalBox">OK</button>
                        </div>
                    </div>
                </div>
                <!-- End company office log modal box -->

                <!-- Delete company office modal box -->
                <div v-if="company" id="delete-company-office-modal-box" class="uk-flex-top delete-company-office-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" @click="resetSelectedJobTitle" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title">Delete Property?</h2>
                        </div>
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Company Name:</h4>
                                <p class="uk-margin-remove">{{ company.name || '-' }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Type:</h4>
                                <p class="uk-margin-remove">{{ selectedOffice.type_id && selectedOffice.type_id.name ? selectedOffice.type_id.name : '-' }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Office Name:</h4>
                                <p class="uk-margin-remove">{{ selectedOffice.name || '-' }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Office Code:</h4>
                                <p class="uk-margin-remove">{{ selectedOffice.code || '-' }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Office Address:</h4>
                                <p class="uk-margin-remove">{{ selectedOffice.address || '-' }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Coordinate Longitude:</h4>
                                <p class="uk-margin-remove">{{ selectedOffice.geoloc.lng || 0 }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Coordinate Latitude:</h4>
                                <p class="uk-margin-remove">{{ selectedOffice.geoloc.lat || 0 }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Radius Attendance:</h4>
                                <p class="uk-margin-remove">{{ selectedOffice.radius || '-' }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Notes:</h4>
                                <p class="uk-margin-remove">{{ selectedOffice.notes || '-' }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <h4 class="uk-margin-remove">Status:</h4>
                                <p class="uk-margin-remove">{{ selectedOffice.status && selectedOffice.status.name ? selectedOffice.status.name : '-' }}</p>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="modalBoxButtonLoading"
                                type="button"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button
                                v-else
                                type="button"
                                class="uk-button"
                                :class="[disabledDeleteCompanyOfficeButton ? 'uk-button-default' : 'uk-button-danger']"
                                :disabled="disabledDeleteCompanyOfficeButton"
                                @click="doDeleteCompanyOffice"
                            >Delete</button>
                        </div>
                    </div>
                </div>
                <!-- End delete company office modal box -->

                <!-- Download template modal box -->
                <div id="download-template-modal-box" class="uk-flex-top download-template-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title uk-text-center">Please select the type of template that you wish to download</h2>
                        </div>
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <button
                                    type="button"
                                    class="uk-button myrobin-button-white-filled uk-card uk-card-default uk-width-1-1 uk-flex uk-flex-middle uk-flex-between"
                                    :disabled="downloadJobTitleTemplateLoading"
                                    @click="doDownloadJobTitleTemplate"
                                >
                                    <div class="uk-flex uk-flex-middle uk-flex-left">
                                        <img :src="`${images}/excel-icon.svg`" alt="Excel icon" loading="lazy" />
                                        <span class="uk-margin-small-left uk-text-primary">Job Title</span>
                                    </div>
                                    <img :src="`${images}/download-blue.svg`" alt="Download icon" loading="lazy" />
                                </button>
                            </div>
                            <div class="uk-width-1-1">
                                <button
                                    type="button"
                                    class="uk-button myrobin-button-white-filled uk-card uk-card-default uk-width-1-1 uk-flex uk-flex-middle uk-flex-between"
                                    :disabled="downloadOfficeTypeTemplateLoading"
                                    @click="doDownloadOfficeTypeTemplate"
                                >
                                    <div class="uk-flex uk-flex-middle uk-flex-left">
                                        <img :src="`${images}/excel-icon.svg`" alt="Excel icon" loading="lazy" />
                                        <span class="uk-margin-small-left uk-text-primary">Office Type</span>
                                    </div>
                                    <img :src="`${images}/download-blue.svg`" alt="Download icon" loading="lazy" />
                                </button>
                            </div>
                            <div class="uk-width-1-1">
                                <button
                                    type="button"
                                    class="uk-button myrobin-button-white-filled uk-card uk-card-default uk-width-1-1 uk-flex uk-flex-middle uk-flex-between"
                                    :disabled="downloadCreateOfficeTemplateLoading"
                                    @click="doDownloadOfficeTemplate"
                                >
                                    <div class="uk-flex uk-flex-middle uk-flex-left">
                                        <img :src="`${images}/excel-icon.svg`" alt="Excel icon" loading="lazy" />
                                        <span class="uk-margin-small-left uk-text-primary">Create Office</span>
                                    </div>
                                    <img :src="`${images}/download-blue.svg`" alt="Download icon" loading="lazy" />
                                </button>
                            </div>
                            <div class="uk-width-1-1">
                                <button
                                    type="button"
                                    class="uk-button myrobin-button-white-filled uk-card uk-card-default uk-width-1-1 uk-flex uk-flex-middle uk-flex-between"
                                    :disabled="downloadSalarySetupTemplateLoading"
                                    @click="doDownloadSalarySetupTemplate"
                                >
                                    <div class="uk-flex uk-flex-middle uk-flex-left">
                                        <img :src="`${images}/excel-icon.svg`" alt="Excel icon" loading="lazy" />
                                        <span class="uk-margin-small-left uk-text-primary">Salary Setup</span>
                                    </div>
                                    <img :src="`${images}/download-blue.svg`" alt="Download icon" loading="lazy" />
                                </button>
                            </div>
                            <div class="uk-width-1-1">
                                <button
                                    type="button"
                                    class="uk-button myrobin-button-white-filled uk-card uk-card-default uk-width-1-1 uk-flex uk-flex-middle uk-flex-between"
                                    :disabled="downloadCreateWorkerRequestTemplateLoading"
                                    @click="doDownloadCreateWorkerRequestTemplate"
                                >
                                    <div class="uk-flex uk-flex-middle uk-flex-left">
                                        <img :src="`${images}/excel-icon.svg`" alt="Excel icon" loading="lazy" />
                                        <span class="uk-margin-small-left uk-text-primary">Worker Request</span>
                                    </div>
                                    <img :src="`${images}/download-blue.svg`" alt="Download icon" loading="lazy" />
                                </button>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button type="button" class="uk-button uk-button-primary" @click="hideDownloadTemplateModalBox">Close</button>
                        </div>
                    </div>
                </div>
                <!-- End download template modal box -->

                <!-- Upload template modal box -->
                <div id="upload-template-modal-box" class="uk-flex-top upload-template-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title uk-text-center">Please select the type of template that you wish to upload</h2>
                        </div>
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <LoaderButton v-if="uploadTemplateLoading" class="uk-width-1-1" />
                                <button
                                    v-else
                                    type="button"
                                    class="uk-button uk-button-primary uk-width-1-1 uk-flex uk-flex-left uk-flex-middle"
                                    :disabled="uploadTemplateLoading"
                                    @click="triggerUploadJobTitleTemplate"
                                >
                                    <img :src="`${images}/upload-outline-white.svg`" alt="Upload icon" loading="lazy" />
                                    <span class="uk-margin-small-left">Upload Job Title</span>
                                    <input
                                        ref="uploadJobTitleTemplate"
                                        class="display-none"
                                        type="file"
                                        accept=".xls, .xlsx"
                                        @change="doUploadJobTitleTemplate"
                                    />
                                </button>
                            </div>
                            <div class="uk-width-1-1">
                                <LoaderButton v-if="uploadTemplateLoading" class="uk-width-1-1" />
                                <button
                                    v-else
                                    type="button"
                                    class="uk-button uk-button-primary uk-width-1-1 uk-flex uk-flex-left uk-flex-middle"
                                    :disabled="uploadTemplateLoading"
                                    @click="triggerUploadOfficeTypeTemplate"
                                >
                                    <img :src="`${images}/upload-outline-white.svg`" alt="Upload icon" loading="lazy" />
                                    <span class="uk-margin-small-left">Upload Office Type</span>
                                    <input
                                        ref="uploadOfficeTypeTemplate"
                                        class="display-none"
                                        type="file"
                                        accept=".xls, .xlsx"
                                        @change="doUploadOfficeTypeTemplate"
                                    />
                                </button>
                            </div>
                            <div class="uk-width-1-1">
                                <LoaderButton v-if="uploadTemplateLoading" class="uk-width-1-1" />
                                <button
                                    v-else
                                    type="button"
                                    class="uk-button uk-button-primary uk-width-1-1 uk-flex uk-flex-left uk-flex-middle"
                                    :disabled="uploadTemplateLoading"
                                    @click="triggerUploadCreateOfficeTemplate"
                                >
                                    <img :src="`${images}/upload-outline-white.svg`" alt="Upload icon" loading="lazy" />
                                    <span class="uk-margin-small-left">Upload Create Office</span>
                                    <input
                                        ref="uploadCreateOfficeTemplate"
                                        class="display-none"
                                        type="file"
                                        accept=".xls, .xlsx"
                                        @change="doUploadCreateOfficeTemplate"
                                    />
                                </button>
                            </div>
                            <div class="uk-width-1-1">
                                <LoaderButton v-if="uploadTemplateLoading" class="uk-width-1-1" />
                                <button
                                    v-else
                                    type="button"
                                    class="uk-button uk-button-primary uk-width-1-1 uk-flex uk-flex-left uk-flex-middle"
                                    :disabled="uploadTemplateLoading"
                                    @click="triggerUploadSalarySetupTemplate"
                                >
                                    <img :src="`${images}/upload-outline-white.svg`" alt="Upload icon" loading="lazy" />
                                    <span class="uk-margin-small-left">Upload Salary Setup</span>
                                    <input
                                        ref="uploadSalarySetupTemplate"
                                        class="display-none"
                                        type="file"
                                        accept=".xls, .xlsx"
                                        @change="doUploadSalarySetupTemplate"
                                    />
                                </button>
                            </div>
                            <div class="uk-width-1-1">
                                <LoaderButton v-if="uploadTemplateLoading" class="uk-width-1-1" />
                                <button
                                    v-else
                                    type="button"
                                    class="uk-button uk-button-primary uk-width-1-1 uk-flex uk-flex-left uk-flex-middle"
                                    :disabled="uploadTemplateLoading"
                                    @click="triggerUploadWorkerRequestTemplate"
                                >
                                    <img :src="`${images}/upload-outline-white.svg`" alt="Upload icon" loading="lazy" />
                                    <span class="uk-margin-small-left">Upload Worker Request</span>
                                    <input
                                        ref="uploadWorkerRequestTemplate"
                                        class="display-none"
                                        type="file"
                                        accept=".xls, .xlsx"
                                        @change="doUploadWorkerRequestTemplate"
                                    />
                                </button>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button type="button" class="uk-button uk-button-primary" @click="hideUploadTemplateModalBox">Close</button>
                        </div>
                    </div>
                </div>
                <!-- End upload template modal box -->

                <!-- Error log modal box -->
                <div id="error-log-modal-box" class="uk-flex-top error-log-modal-box uk-modal-container" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title uk-text-center">Company Template Error Log</h2>
                        </div>
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <p class="uk-margin-remove">Incorrect data</p>
                            </div>
                            <div class="uk-width-1-1">
                                <div class="uk-overflow-auto">
                                    <table class="uk-table uk-table-small uk-table-striped">
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>Date</th>
                                                <th>Template Type</th>
                                                <th>Error Details</th>
                                                <th class="uk-table-shrink">Action</th>
                                            </tr>
                                        </thead>
                                        <Loading v-if="companyTemplateErrorLogLoading" :colspan="5" />
                                        <template v-else>
                                            <tbody v-if="
                                                companyTemplateErrorLogList.jobTitleErrorLog ||
                                                companyTemplateErrorLogList.officeTypeErrorLog ||
                                                companyTemplateErrorLogList.companyOfficeErrorLog ||
                                                companyTemplateErrorLogList.salaryOfficeErrorLog ||
                                                companyTemplateErrorLogList.workerRequestErrorLog
                                            ">
                                                <tr v-if="companyTemplateErrorLogList.jobTitleErrorLog">
                                                    <td v-if="companyTemplateErrorLogList.jobTitleErrorLog && companyTemplateErrorLogList.jobTitleErrorLog.file_name">
                                                        {{ companyTemplateErrorLogList.jobTitleErrorLog.file_name }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.jobTitleErrorLog && companyTemplateErrorLogList.jobTitleErrorLog.date">
                                                        {{ companyTemplateErrorLogList.jobTitleErrorLog.date && dateWithSlash(companyTemplateErrorLogList.jobTitleErrorLog.date) }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.jobTitleErrorLog && companyTemplateErrorLogList.jobTitleErrorLog.template_type">
                                                        {{ companyTemplateErrorLogList.jobTitleErrorLog.template_type }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.jobTitleErrorLog && companyTemplateErrorLogList.jobTitleErrorLog.incorrect_datas.length > 0">
                                                        <button
                                                            type="button"
                                                            class="uk-button uk-button-link uk-text-primary text-underline"
                                                            @click="showJobTitleErrorLogDetailModalBox(companyTemplateErrorLogList.jobTitleErrorLog.incorrect_datas)"
                                                        >View</button>
                                                    </td>
                                                    <td 
                                                        v-if="companyTemplateErrorLogList.jobTitleErrorLog && companyTemplateErrorLogList.jobTitleErrorLog.incorrect_datas.length > 0"
                                                        class="uk-table-shrink"
                                                    >
                                                        <LoaderButton v-if="downloadJobTitleErrorLogLoading" />
                                                        <button
                                                            v-else
                                                            type="button"
                                                            class="uk-button uk-button-primary uk-flex uk-flex-middle uk-flex-center uk-border-rounded"
                                                            :disabled="downloadJobTitleErrorLogLoading"
                                                            @click="downloadJobTitleErrorLogData(companyTemplateErrorLogList.jobTitleErrorLog.incorrect_datas)"
                                                        >
                                                            <img :src="`${images}/download-outline-white.svg`" alt="Download icon" loading="lazy" />
                                                            <span class="uk-margin-small-left">Download</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr v-if="companyTemplateErrorLogList.officeTypeErrorLog">
                                                    <td v-if="companyTemplateErrorLogList.officeTypeErrorLog && companyTemplateErrorLogList.officeTypeErrorLog.file_name">
                                                        {{ companyTemplateErrorLogList.officeTypeErrorLog.file_name }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.officeTypeErrorLog && companyTemplateErrorLogList.officeTypeErrorLog.date">
                                                        {{ companyTemplateErrorLogList.officeTypeErrorLog.date && dateWithSlash(companyTemplateErrorLogList.officeTypeErrorLog.date) }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.officeTypeErrorLog && companyTemplateErrorLogList.officeTypeErrorLog.template_type">
                                                        {{ companyTemplateErrorLogList.officeTypeErrorLog.template_type }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.officeTypeErrorLog && companyTemplateErrorLogList.officeTypeErrorLog.incorrect_datas.length > 0">
                                                        <button
                                                            type="button"
                                                            class="uk-button uk-button-link uk-text-primary text-underline"
                                                            @click="showOfficeTypeErrorLogDetailModalBox(companyTemplateErrorLogList.officeTypeErrorLog.incorrect_datas)"
                                                        >View</button>
                                                    </td>
                                                    <td
                                                        v-if="companyTemplateErrorLogList.officeTypeErrorLog && companyTemplateErrorLogList.officeTypeErrorLog.incorrect_datas.length > 0"
                                                        class="uk-table-shrink"
                                                    >
                                                        <LoaderButton v-if="downloadOfficeTypeErrorLogLoading" />
                                                        <button
                                                            v-else
                                                            type="button"
                                                            class="uk-button uk-button-primary uk-flex uk-flex-middle uk-flex-center uk-border-rounded"
                                                            :disabled="downloadOfficeTypeErrorLogLoading"
                                                            @click="downloadOfficeTypeErrorLogData(companyTemplateErrorLogList.officeTypeErrorLog.incorrect_datas)"
                                                        >
                                                            <img :src="`${images}/download-outline-white.svg`" alt="Download icon" loading="lazy" />
                                                            <span class="uk-margin-small-left">Download</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr v-if="companyTemplateErrorLogList.companyOfficeErrorLog">
                                                    <td v-if="companyTemplateErrorLogList.companyOfficeErrorLog && companyTemplateErrorLogList.companyOfficeErrorLog.file_name">
                                                        {{ companyTemplateErrorLogList.companyOfficeErrorLog.file_name }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.companyOfficeErrorLog && companyTemplateErrorLogList.companyOfficeErrorLog.date">
                                                        {{ companyTemplateErrorLogList.companyOfficeErrorLog.date && dateWithSlash(companyTemplateErrorLogList.companyOfficeErrorLog.date) }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.companyOfficeErrorLog && companyTemplateErrorLogList.companyOfficeErrorLog.template_type">
                                                        {{ companyTemplateErrorLogList.companyOfficeErrorLog.template_type }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.companyOfficeErrorLog && companyTemplateErrorLogList.companyOfficeErrorLog.incorrect_datas.length > 0">
                                                        <button
                                                            type="button"
                                                            class="uk-button uk-button-link uk-text-primary text-underline"
                                                            @click="showCreateOfficeErrorLogDetailModalBox(companyTemplateErrorLogList.companyOfficeErrorLog.incorrect_datas)"
                                                        >View</button>
                                                    </td>
                                                    <td
                                                        v-if="companyTemplateErrorLogList.companyOfficeErrorLog && companyTemplateErrorLogList.companyOfficeErrorLog.incorrect_datas.length > 0"
                                                        class="uk-table-shrink"
                                                    >
                                                        <LoaderButton v-if="downloadCreateOfficeErrorLogLoading" />
                                                        <button
                                                            v-else
                                                            type="button"
                                                            class="uk-button uk-button-primary uk-flex uk-flex-middle uk-flex-center uk-border-rounded"
                                                            :disabled="downloadCreateOfficeErrorLogLoading"
                                                            @click="downloadCreateOfficeErrorLogData(companyTemplateErrorLogList.companyOfficeErrorLog.incorrect_datas)"
                                                        >
                                                            <img :src="`${images}/download-outline-white.svg`" alt="Download icon" loading="lazy" />
                                                            <span class="uk-margin-small-left">Download</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr v-if="companyTemplateErrorLogList.salaryOfficeErrorLog">
                                                    <td v-if="companyTemplateErrorLogList.salaryOfficeErrorLog && companyTemplateErrorLogList.salaryOfficeErrorLog.file_name">
                                                        {{ companyTemplateErrorLogList.salaryOfficeErrorLog.file_name }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.salaryOfficeErrorLog && companyTemplateErrorLogList.salaryOfficeErrorLog.date">
                                                        {{ companyTemplateErrorLogList.salaryOfficeErrorLog.date && dateWithSlash(companyTemplateErrorLogList.salaryOfficeErrorLog.date) }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.salaryOfficeErrorLog && companyTemplateErrorLogList.salaryOfficeErrorLog.template_type">
                                                        {{ companyTemplateErrorLogList.salaryOfficeErrorLog.template_type }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.salaryOfficeErrorLog && companyTemplateErrorLogList.salaryOfficeErrorLog.incorrect_datas.length > 0">
                                                        <button
                                                            type="button"
                                                            class="uk-button uk-button-link uk-text-primary text-underline"
                                                            @click="showSalarySettingErrorLogDetailModalBox(companyTemplateErrorLogList.salaryOfficeErrorLog.incorrect_datas)"
                                                        >View</button>
                                                    </td>
                                                    <td
                                                        v-if="companyTemplateErrorLogList.salaryOfficeErrorLog && companyTemplateErrorLogList.salaryOfficeErrorLog.incorrect_datas.length > 0"
                                                        class="uk-table-shrink"
                                                    >
                                                        <LoaderButton v-if="downloadCreateOfficeErrorLogLoading" />
                                                        <button
                                                            v-else
                                                            type="button"
                                                            class="uk-button uk-button-primary uk-flex uk-flex-middle uk-flex-center uk-border-rounded"
                                                            :disabled="downloadCreateOfficeErrorLogLoading"
                                                            @click="downloadCreateOfficeErrorLogData(companyTemplateErrorLogList.salaryOfficeErrorLog.incorrect_datas)"
                                                        >
                                                            <img :src="`${images}/download-outline-white.svg`" alt="Download icon" loading="lazy" />
                                                            <span class="uk-margin-small-left">Download</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr v-if="companyTemplateErrorLogList.workerRequestErrorLog">
                                                    <td v-if="companyTemplateErrorLogList.workerRequestErrorLog && companyTemplateErrorLogList.workerRequestErrorLog.file_name">
                                                        {{ companyTemplateErrorLogList.workerRequestErrorLog.file_name }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.workerRequestErrorLog && companyTemplateErrorLogList.workerRequestErrorLog.date">
                                                        {{ companyTemplateErrorLogList.workerRequestErrorLog.date && dateWithSlash(companyTemplateErrorLogList.workerRequestErrorLog.date) }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.workerRequestErrorLog && companyTemplateErrorLogList.workerRequestErrorLog.template_type">
                                                        {{ companyTemplateErrorLogList.workerRequestErrorLog.template_type }}
                                                    </td>
                                                    <td v-if="companyTemplateErrorLogList.workerRequestErrorLog && companyTemplateErrorLogList.workerRequestErrorLog.incorrect_datas.length > 0">
                                                        <button
                                                            type="button"
                                                            class="uk-button uk-button-link uk-text-primary text-underline"
                                                            @click="showWorkerRequestErrorLogDetailModalBox(companyTemplateErrorLogList.workerRequestErrorLog.incorrect_datas)"
                                                        >View</button>
                                                    </td>
                                                    <td
                                                        v-if="companyTemplateErrorLogList.workerRequestErrorLog && companyTemplateErrorLogList.workerRequestErrorLog.incorrect_datas.length > 0"
                                                        class="uk-table-shrink"
                                                    >
                                                        <LoaderButton v-if="downloadCreateOfficeErrorLogLoading" />
                                                        <button
                                                            v-else
                                                            type="button"
                                                            class="uk-button uk-button-primary uk-flex uk-flex-middle uk-flex-center uk-border-rounded"
                                                            :disabled="downloadCreateOfficeErrorLogLoading"
                                                            @click="downloadCreateOfficeErrorLogData(companyTemplateErrorLogList.workerRequestErrorLog.incorrect_datas)"
                                                        >
                                                            <img :src="`${images}/download-outline-white.svg`" alt="Download icon" loading="lazy" />
                                                            <span class="uk-margin-small-left">Download</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <EmptyTable v-else :colspan="5" />
                                        </template>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button type="button" class="uk-button uk-button-primary" @click="hideErrorLogModalBox">Close</button>
                        </div>
                    </div>
                </div>
                <!-- End error log modal box -->

                <!-- Upload loader modal box -->
                <div id="upload-loader-modal-box" class="upload-loader-modal-box" esc-close="false" bg-close="false" uk-modal>
                    <div class="uk-modal-dialog uk-width-1-4@s">
                        <div class="uk-modal-body uk-flex uk-flex-column uk-flex-middle uk-flex-center">
                            <div class="loader"></div>
                            <p>Uploading...</p>
                        </div>
                    </div>
                </div>
                <!-- End upload loader modal box -->

                <!-- Job title error log detail modal box -->
                <div id="job-title-error-log-detail-modal-box" class="job-title-error-log-detail-modal-box" esc-close="false" bg-close="false" uk-modal>
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title uk-text-center">Company Template Error Log</h2>
                        </div>
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <div class="uk-flex uk-flex-middle uk-flex-between">
                                    <p class="uk-margin-remove">Incorrect data</p>
                                    <template v-if="selectedJobTitleIncorrectDatas.length > 0">
                                        <LoaderButton v-if="downloadJobTitleErrorLogLoading" />
                                        <button
                                            v-else
                                            type="button"
                                            class="uk-button uk-button-primary uk-flex uk-flex-middle uk-flex-center uk-border-rounded"
                                            :disabled="downloadJobTitleErrorLogLoading"
                                            @click="downloadJobTitleErrorLogData(selectedJobTitleIncorrectDatas)"
                                        >
                                            <img :src="`${images}/download-outline-white.svg`" alt="Download icon" loading="lazy" />
                                            <span class="uk-margin-small-left">Download</span>
                                        </button>
                                    </template>
                                </div>
                            </div>
                            <div class="uk-width-1-1">
                                <div class="uk-overflow-auto">
                                    <table class="uk-table uk-table-small uk-table-striped">
                                        <thead>
                                            <tr>
                                                <th class="uk-text-bold">Job Name</th>
                                                <th class="uk-text-bold">Error Status</th>
                                            </tr>
                                        </thead>
                                        <Loading v-if="companyTemplateErrorLogLoading" :colspan="2" />
                                        <template v-else>
                                            <tbody v-if="selectedJobTitleIncorrectDatas.length > 0">
                                                <tr v-for="(data, index) in selectedJobTitleIncorrectDatas" :key="index">
                                                    <td class="uk-text-bold">{{ data && data['*Job Name'] || '-' }}</td>
                                                    <td>{{ data && data.status || '-' }}</td>
                                                </tr>
                                            </tbody>
                                            <EmptyTable v-else :colspan="2" />
                                        </template>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button type="button" class="uk-button uk-button-primary" @click="hideJobTitleErrorLogDetailModalBox">Close</button>
                        </div>
                    </div>
                </div>
                <!-- End job title error log detail modal box -->

                <!-- Office type error log detail modal box -->
                <div id="office-type-error-log-detail-modal-box" class="office-type-error-log-detail-modal-box uk-modal-container" esc-close="false" bg-close="false" uk-modal>
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title uk-text-center">Company Template Error Log</h2>
                        </div>
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <div class="uk-flex uk-flex-middle uk-flex-between">
                                    <p class="uk-margin-remove">Incorrect data</p>
                                    <template v-if="selectedOfficeTypeIncorrectDatas.length > 0">
                                        <LoaderButton v-if="downloadOfficeTypeErrorLogLoading" />
                                        <button
                                            v-else
                                            type="button"
                                            class="uk-button uk-button-primary uk-flex uk-flex-middle uk-flex-center uk-border-rounded"
                                            :disabled="downloadOfficeTypeErrorLogLoading"
                                            @click="downloadOfficeTypeErrorLogData(selectedOfficeTypeIncorrectDatas)"
                                        >
                                            <img :src="`${images}/download-outline-white.svg`" alt="Download icon" loading="lazy" />
                                            <span class="uk-margin-small-left">Download</span>
                                        </button>
                                    </template>
                                </div>
                            </div>
                            <div class="uk-width-1-1">
                                <div class="uk-overflow-auto">
                                    <table class="uk-table uk-table-small uk-table-divider">
                                        <thead>
                                            <tr>
                                                <th>Office Type Code</th>
                                                <th>Description</th>
                                                <th>Error Status</th>
                                            </tr>
                                        </thead>
                                        <Loading v-if="companyTemplateErrorLogLoading" :colspan="3" />
                                        <template v-else>
                                            <tbody v-if="selectedOfficeTypeIncorrectDatas.length > 0">
                                                <tr v-for="(data, index) in selectedOfficeTypeIncorrectDatas" :key="index">
                                                    <td class="uk-text-bold">{{ data && data['*Office Type Code'] || '-' }}</td>
                                                    <td>{{ data && data['*Description'] || '-' }}</td>
                                                    <td>{{ data && data.status || '-' }}</td>
                                                </tr>
                                            </tbody>
                                            <EmptyTable v-else :colspan="3" />
                                        </template>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button type="button" class="uk-button uk-button-primary" @click="hideOfficeTypeErrorLogDetailModalBox">Close</button>
                        </div>
                    </div>
                </div>
                <!-- End office type error log detail modal box -->

                <!-- Create office error log detail modal box -->
                <div id="create-office-error-log-detail-modal-box" class="create-office-error-log-detail-modal-box uk-modal-container" esc-close="false" bg-close="false" uk-modal>
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title uk-text-center">Company Template Error Log</h2>
                        </div>
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <div class="uk-flex uk-flex-middle uk-flex-between">
                                    <p class="uk-margin-remove">Incorrect data</p>
                                    <template v-if="selectedCreateOfficeIncorrectDatas.length > 0">
                                        <LoaderButton v-if="downloadCreateOfficeErrorLogLoading" />
                                        <button
                                            v-else
                                            type="button"
                                            class="uk-button uk-button-primary uk-flex uk-flex-middle uk-flex-center uk-border-rounded"
                                            :disabled="downloadCreateOfficeErrorLogLoading"
                                            @click="downloadCreateOfficeErrorLogData(selectedCreateOfficeIncorrectDatas)"
                                        >
                                            <img :src="`${images}/download-outline-white.svg`" alt="Download icon" loading="lazy" />
                                            <span class="uk-margin-small-left">Download</span>
                                        </button>
                                    </template>
                                </div>
                            </div>
                            <div class="uk-width-1-1">
                                <div class="uk-overflow-auto">
                                    <table class="uk-table uk-table-small uk-table-divider">
                                        <thead>
                                            <tr>
                                                <th>Office Type</th>
                                                <th>Office Name</th>
                                                <th>Office Code</th>
                                                <th>Office City</th>
                                                <th>Office Address</th>
                                                <th>Attendance Radius</th>
                                                <th>Error Status</th>
                                            </tr>
                                        </thead>
                                        <Loading v-if="companyTemplateErrorLogLoading" :colspan="7" />
                                        <template v-else>
                                            <tbody v-if="selectedCreateOfficeIncorrectDatas.length > 0">
                                                <tr v-for="(data, index) in selectedCreateOfficeIncorrectDatas" :key="index">
                                                    <td class="uk-text-bold">{{ data && data['*Office Type'] || '-' }}</td>
                                                    <td>{{ data && data['*Office Name'] || '-' }}</td>
                                                    <td>{{ data && data['*Office Code'] || '-' }}</td>
                                                    <td>{{ data && data['*Office City'] || '-' }}</td>
                                                    <td>{{ data && data['*Office Address'] || '-' }}</td>
                                                    <td>{{ data && data['*Attendance Radius'] !== null ? data['*Attendance Radius'] : '-' }}</td>
                                                    <td>{{ data && data.status || '-' }}</td>
                                                </tr>
                                            </tbody>
                                            <EmptyTable v-else :colspan="7" />
                                        </template>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button type="button" class="uk-button uk-button-primary" @click="hideCreateOfficeErrorLogDetailModalBox">Close</button>
                        </div>
                    </div>
                </div>
                <!-- End create office error log detail modal box -->

                <!-- Salary setup error log modal box -->
                <div id="salary-setup-error-log-modal-box" class="salary-setup-error-log-modal-box uk-modal-container"
                    esc-close="false" bg-close="false" uk-modal>
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title uk-text-center">Salary Setup Error Log</h2>
                        </div>
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <div class="uk-flex uk-flex-middle uk-flex-between">
                                    <p class="uk-margin-remove">Incorrect data</p>
                                    <template v-if="selectedSalarySettingIncorrectDatas.length > 0">
                                        <LoaderButton v-if="downloadSalarySettingErrorLogLoading" />
                                        <button v-else type="button"
                                            class="uk-button uk-button-primary uk-flex uk-flex-middle uk-flex-center uk-border-rounded"
                                            :disabled="downloadSalarySettingErrorLogLoading"
                                            @click="downloadSalarySettingErrorLogData(selectedSalarySettingIncorrectDatas)">
                                            <img :src="`${images}/download-outline-white.svg`" alt="Download icon"
                                                loading="lazy" />
                                            <span class="uk-margin-small-left">Download</span>
                                        </button>
                                    </template>
                                </div>
                            </div>
                            <div class="uk-width-1-1">
                                <div class="uk-overflow-auto">
                                    <table class="uk-table uk-table-small uk-table-divider">
                                        <thead>
                                            <tr>
                                                <th>Job Title Name</th>
                                                <th>Office Name</th>
                                                <th>Fee Normal (Rp)</th>
                                                <th>Fee Normal Unit</th>
                                                <th>Error Status</th>
                                            </tr>
                                        </thead>
                                        <Loading v-if="companyTemplateErrorLogLoading" :colspan="7" />
                                        <template v-else>
                                            <tbody v-if="selectedSalarySettingIncorrectDatas.length > 0">
                                                <tr v-for="(data, index) in selectedSalarySettingIncorrectDatas"
                                                    :key="index">
                                                    <td>{{ data['Job Title Name*'] || '-' }}</td>
                                                    <td>{{ data['Office Name*'] || '-' }}</td>
                                                    <td>{{ data['Fee Normal (Rp)*'] || '-' }}</td>
                                                    <td>{{ data['Fee Normal Unit*'] || '-' }}</td>
                                                    <td>{{ data.status || '-' }}</td>
                                                </tr>
                                            </tbody>
                                            <EmptyTable v-else :colspan="5" />
                                        </template>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button type="button" class="uk-button uk-button-primary"
                                @click="hideSalarySettingErrorLogModalBox">Close</button>
                        </div>
                    </div>
                </div>
                <!-- End of salary setup error log modal box -->

                <!-- Create worker request log modal box -->
                <div id="worker-request-error-log-modal-box"
                    class="worker-request-error-log-modal-box uk-modal-container" esc-close="false" bg-close="false"
                    uk-modal>
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title uk-text-center">Worker Request Error Log</h2>
                        </div>
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <div class="uk-flex uk-flex-middle uk-flex-between">
                                    <p class="uk-margin-remove">Incorrect data</p>
                                    <template v-if="selectedWorkerRequestIncorrectDatas.length > 0">
                                        <LoaderButton v-if="downloadWorkerRequestErrorLogLoading" />
                                        <button v-else type="button"
                                            class="uk-button uk-button-primary uk-flex uk-flex-middle uk-flex-center uk-border-rounded"
                                            :disabled="downloadWorkerRequestErrorLogLoading"
                                            @click="downloadWorkerRequestErrorLogData(selectedWorkerRequestIncorrectDatas)">
                                            <img :src="`${images}/download-outline-white.svg`" alt="Download icon"
                                                loading="lazy" />
                                            <span class="uk-margin-small-left">Download</span>
                                        </button>
                                    </template>
                                </div>
                            </div>
                            <div class="uk-width-1-1">
                                <div class="uk-overflow-auto">
                                    <table class="uk-table uk-table-small uk-table-divider">
                                        <thead>
                                            <tr>
                                                <th>Office Name</th>
                                                <th>Job Title Name</th>
                                                <th>Start Date (dd/mm/yyyy)</th>
                                                <th>Due Date (dd/mm/yyyy)</th>
                                                <th>Start Work (dd/mm/yyyy)</th>
                                                <th>Manpower (Numbers)</th>
                                                <th>Error Status</th>
                                            </tr>
                                        </thead>
                                        <Loading v-if="companyTemplateErrorLogLoading" :colspan="7" />
                                        <template v-else>
                                            <tbody v-if="selectedWorkerRequestIncorrectDatas.length > 0">
                                                <tr v-for="(data, index) in selectedWorkerRequestIncorrectDatas"
                                                    :key="index">
                                                    <td>{{ data['Office Name*'] || '-' }}</td>
                                                    <td>{{ data['Job Title Name*'] || '-' }}</td>
                                                    <td>{{ data['Start Date (dd/mm/yyyy)*'] ? dateWithSlash(data['Start Date (dd/mm/yyyy)*']) : '-' }}</td>
                                                    <td>{{ data['Due Date (dd/mm/yyyy)*'] ? dateWithSlash(data['Due Date (dd/mm/yyyy)*']) : '-' }}</td>
                                                    <td>{{ data['Start Work (dd/mm/yyyy)*'] ? dateWithSlash(data['Start Work (dd/mm/yyyy)*']) : '-' }}</td>
                                                    <td>{{ data['Manpower (Numbers)*'] || '-' }}</td>
                                                    <td>{{ data.status || '-' }}</td>
                                                </tr>
                                            </tbody>
                                            <EmptyTable v-else :colspan="7" />
                                        </template>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button type="button" class="uk-button uk-button-primary"
                                @click="hideWorkerRequestErrorLogModalBox">Close</button>
                        </div>
                    </div>
                </div>
                <!-- End of create worker request error log modal box -->

                <!-- Setting modal box -->
                <div v-if="company" id="setting-modal-box" class="uk-flex-top setting-modal-box" uk-modal esc-close="false" bg-close="false">
                    <div class="uk-modal-dialog uk-margin-auto-vertical">
                        <button class="uk-modal-close-default" type="button" uk-close></button>
                        <div class="uk-modal-header">
                            <h2 class="uk-modal-title uk-text-center">Settings</h2>
                        </div>
                        <div class="uk-modal-body uk-flex uk-flex-center uk-flex-column uk-text-center">
                            <div class="uk-flex uk-flex-column">
                                <button class="uk-button uk-button-primary" @click="goToOvertimeReportViewSetting({companyId: company._id})">Overtime Report View</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End Setting modal box -->
            </template>
        </template>
        <no-internet-connection v-else></no-internet-connection>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import formatter from '@/utils/formatter';
import {
    excelDownloader,
    excelDownloaderWithAdditionalSheet
} from "@/utils/helper";

export default {
    components: {
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        Loading: () => import('@/components/globals/tables/Loading'),
        LoaderButton: () => import('@/components/globals/LoaderButton'),
        VueQrcode
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            internetConnection: true,
            isLoading: false,
            modalBoxButtonLoading: false,
            jobTitleTableLoading: false,
            companyOfficeTableLoading: false,
            modalBoxLoading: false,
            user_cred: getUserLogin(),
            company: null,
            statusOptions: [
                { name: 'Active', value: 1 },
                { name: 'Non Active', value: 0 }
            ],
            actionType: null,
            selectedJobTitle: {
                id: null,
                company_id: null,
                internal_shipper: false,
                status: null,
                name: ''
            },
            jobListOptions: [],
            selectedOffice: {
                id: null,
                company_id: null,
                type_id: null,
                status: null,
                name: null,
                code: null,
                domicile_city_id: null,
                notes: null,
                address: null,
                radius: null,
                geoloc: {
                    lat: null,
                    lng: null
                }
            },
            selectedJobRole: null,
            qrCode: {
                title: null,
                company_id: null,
                warehouse_id: null,
                role_id: null
            },
            officeTypeOptions: [],
            domicileOptions: [],
            uploadTemplateLoading: false,
            downloadJobTitleTemplateLoading: false,
            downloadOfficeTypeTemplateLoading: false,
            downloadCreateOfficeTemplateLoading: false,
            downloadSalarySetupTemplateLoading: false,
            downloadCreateWorkerRequestTemplateLoading: false,
            companyTemplateErrorLogList: {
                jobTitleErrorLog: null,
                officeTypeErrorLog: null,
                companyOfficeErrorLog: null,
                salaryOfficeErrorLog: null,
                workerRequestErrorLog: null,
            },
            companyTemplateErrorLogLoading: false,
            downloadJobTitleErrorLogLoading: false,
            downloadOfficeTypeErrorLogLoading: false,
            downloadCreateOfficeErrorLogLoading: false,
            downloadSalarySettingErrorLogLoading: false,
            downloadWorkerRequestErrorLogLoading: false,
            selectedJobTitleIncorrectDatas: [],
            selectedOfficeTypeIncorrectDatas: [],
            selectedCreateOfficeIncorrectDatas: [],
            selectedSalarySettingIncorrectDatas: [],
            selectedWorkerRequestIncorrectDatas: [],
        };
    },
    computed: {
        ...mapGetters({
            company_data: 'company/company',
            property_types: 'property_type/property_types',
            office_logs: 'property/office_logs',
        }),
        disabledFormJobTitleButtonSave() {
            let disabled = true;

            if (this.actionType === 'createJobTitle') {
                if (
                    this.company === null ||
                    this.selectedJobTitle.company_id === null ||
                    this.selectedJobTitle.status === null ||
                    this.selectedJobTitle.name === null ||
                    this.selectedJobTitle.name === '' ||
                    this.$validator.errors.any('selectedJobTitle')
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else if (this.actionType === 'editJobTitle') {
                if (
                    this.company === null ||
                    this.selectedJobTitle.id === null ||
                    this.selectedJobTitle.company_id === null ||
                    this.selectedJobTitle.status === null ||
                    this.selectedJobTitle.name === null ||
                    this.selectedJobTitle.name === '' ||
                    this.$validator.errors.any('selectedJobTitle')
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else {
                disabled = true;
            }

            return disabled;
        },
        disabledDeleteJobTitleButton() {
            let disabled = true;

            if (
                this.company === null || 
                this.selectedJobTitle.id === null ||
                this.selectedJobTitle.name === null ||
                this.selectedJobTitle.status === null
            ) {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        },
        disabledGenerateQrCodeButton() {
            let disabled = true;

            if (this.company === null || this.selectedJobRole === null || this.selectedOffice.id === null) {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        },
        filledQr() {
            let show = false;

            if (
                this.qrCode.company_id === '' ||
                this.qrCode.warehouse_id === '' ||
                this.qrCode.role_id === '' ||
                this.qrCode.title === ''
            ) {
                show = false;
            } else {
                show = true;
            }

            return show;
        },
        disabledDeleteCompanyOfficeButton() {
            let disabled = true;

            if (
                this.company === null ||
                this.selectedOffice.id === null
            ) {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        },
        disabledCreateOrEditCompanyOfficeButton() {
            let disabled = true;

            if (this.actionType === 'createCompanyOffice') {
                if (
                    this.company === null ||
                    this.selectedOffice.company_office_id === null ||
                    this.selectedOffice.type_id === null ||
                    this.selectedOffice.status === null ||
                    this.selectedOffice.name === null ||
                    this.selectedOffice.name === '' ||
                    this.selectedOffice.code === null ||
                    this.selectedOffice.code === '' ||
                    this.selectedOffice.domicile_city_id === null ||
                    this.selectedOffice.notes === null ||
                    this.selectedOffice.notes === '' ||
                    this.selectedOffice.address === null ||
                    this.selectedOffice.address === '' ||
                    this.selectedOffice.radius === null ||
                    this.selectedOffice.radius === '' ||
                    isNaN(this.selectedOffice.radius) ||
                    isNaN(this.selectedOffice.geoloc.lat) ||
                    isNaN(this.selectedOffice.geoloc.lng) ||
                    this.$validator.errors.any('selectedOffice')
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else if (this.actionType === 'editCompanyOffice') {
                if (
                    this.company === null ||
                    this.selectedOffice.id === null ||
                    this.selectedOffice.company_office_id === null ||
                    this.selectedOffice.type_id === null ||
                    this.selectedOffice.status === null ||
                    this.selectedOffice.name === null ||
                    this.selectedOffice.name === '' ||
                    this.selectedOffice.code === null ||
                    this.selectedOffice.code === '' ||
                    this.selectedOffice.domicile_city_id === null ||
                    this.selectedOffice.notes === null ||
                    this.selectedOffice.notes === '' ||
                    this.selectedOffice.address === null ||
                    this.selectedOffice.address === '' ||
                    this.selectedOffice.radius === null ||
                    this.selectedOffice.radius === '' ||
                    isNaN(this.selectedOffice.radius) ||
                    isNaN(this.selectedOffice.geoloc.lat) ||
                    isNaN(this.selectedOffice.geoloc.lng) ||
                    this.$validator.errors.any('selectedOffice')
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else {
                disabled = true;
            }

            return disabled;
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.create-or-edit-job-title-modal-box')) window.UIkit.modal('.create-or-edit-job-title-modal-box').$destroy(true);
        if (window.UIkit.modal('.create-or-edit-office-modal-box')) window.UIkit.modal('.create-or-edit-office-modal-box').$destroy(true);
        if (window.UIkit.modal('.delete-job-title-modal-box')) window.UIkit.modal('.delete-job-title-modal-box').$destroy(true);
        if (window.UIkit.modal('.job-title-list-modal-box')) window.UIkit.modal('.job-title-list-modal-box').$destroy(true);
        if (window.UIkit.modal('.qr-code-modal-box')) window.UIkit.modal('.qr-code-modal-box').$destroy(true);
        if (window.UIkit.modal('.company-office-log-modal-box')) window.UIkit.modal('.company-office-log-modal-box').$destroy(true);
        if (window.UIkit.modal('.delete-company-office-modal-box')) window.UIkit.modal('.delete-company-office-modal-box').$destroy(true);
        if (window.UIkit.modal('.download-template-modal-box')) window.UIkit.modal('.download-template-modal-box').$destroy(true);
        if (window.UIkit.modal('.upload-template-modal-box')) window.UIkit.modal('.upload-template-modal-box').$destroy(true);
        if (window.UIkit.modal('.error-log-modal-box')) window.UIkit.modal('.error-log-modal-box').$destroy(true);
        if (window.UIkit.modal('.upload-loader-modal-box')) window.UIkit.modal('.upload-loader-modal-box').$destroy(true);
        if (window.UIkit.modal('.job-title-error-log-detail-modal-box')) window.UIkit.modal('.job-title-error-log-detail-modal-box').$destroy(true);
        if (window.UIkit.modal('.office-type-error-log-detail-modal-box')) window.UIkit.modal('.office-type-error-log-detail-modal-box').$destroy(true);
        if (window.UIkit.modal('.create-office-error-log-detail-modal-box')) window.UIkit.modal('.create-office-error-log-detail-modal-box').$destroy(true);
        if (window.UIkit.modal('.salary-setup-error-log-modal-box')) window.UIkit.modal('.salary-setup-error-log-modal-box').$destroy(true);
        if (window.UIkit.modal('.worker-request-error-log-modal-box')) window.UIkit.modal('.worker-request-error-log-modal-box').$destroy(true);
        if (window.UIkit.modal('.setting-modal-box')) window.UIkit.modal('.setting-modal-box').$destroy(true);
    },
    async mounted() {
        try {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.isLoading = true;
                await this.setCompany();
                await this.setOfficeTypeOptions();
                await this.setDomicileOptions();
            } else {
                this.internetConnection = false;
            }
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getCompanyDetail: 'company/getCompanyDetail',
            createCompanyRole: 'company_role/createCompanyRole',
            updateCompanyRole: 'company_role/updateCompanyRole',
            deleteCompanyRole: 'company_role/deleteCompanyRole',
            createProperty: 'property/createProperty',
            updateProperty: 'property/updateProperty',
            deleteProperty: 'property/deleteProperty',
            getPropertyTypes: 'property_type/getPropertyTypes',
            getCompanyOfficeLog: 'property/getCompanyOfficeLog',
            getPropertyRoleSettingNonPaginate: 'property_role_setting/getPropertyRoleSettingNonPaginate',
            getCity: 'option_data/getCity',
            downloadCompanyRoleTemplate: 'company_role/downloadCompanyRoleTemplate',
            downloadOfficeTypeTemplate: 'company/downloadOfficeTypeTemplate',
            downloadOfficeTemplate: 'company/downloadOfficeTemplate',
            downloadSalarySetupTemplate: 'property_role_setting/downloadSalarySetupTemplate',
            downloadWorkerRequestTemplate: 'initiation/downloadWorkerRequestTemplate',
            uploadJobTitleTemplate: 'company_role/uploadJobTitleTemplate',
            uploadOfficeTypeTemplate: 'property_type/uploadOfficeTypeTemplate',
            uploadCreateOfficeTemplate: 'company/uploadCreateOfficeTemplate',
            uploadSalarySetupTemplate: 'property_role_setting/uploadSalarySetupTemplate',
            uploadWorkerRequestTemplate: 'initiation/uploadWorkerRequestTemplate',
            getCompanyTemplateErrorLogList: 'company/getCompanyTemplateErrorLogList'
        }),
        cleanWhiteSpace() {
            this.selectedJobTitle.name = this.selectedJobTitle.name.trim();
        },
        async setCompany() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await this.getCompanyDetail(this.$route.params.company_id);
                    if (this.company_data) {
                        this.company = this.company_data;
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setJobListOptions({ officeId }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.jobListOptions = await this.getPropertyRoleSettingNonPaginate({ company_office_id: officeId });
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setOfficeTypeOptions() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await this.getPropertyTypes({ company_id: this.$route.params.company_id });
                    if (this.property_types && this.property_types.docs.length > 0) {
                        this.officeTypeOptions = this.property_types.docs.map((item) => ({ name: item.code, value: item._id }));
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setDomicileOptions() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    const response = await this.getCity();
                    if (response && response.length > 0) {
                        this.domicileOptions = response.map((item) => ({ value: item._id, name: item.name }));
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showCreateJobTitleModalBox({ actionType, id, internarShipper, status, name }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.actionType = actionType;
                    this.resetSelectedJobTitle();
                    if (actionType === 'createJobTitle') {
                        this.fillSelectedJobTitle({ id: null, companyId: this.company._id, internarShipper: false, status: null, name: '' });
                    } else if (actionType === 'editJobTitle') {
                        this.fillSelectedJobTitle({ id, companyId: this.company._id, internarShipper, status, name });
                    }
                    await window.UIkit.modal('#create-or-edit-job-title-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showDeleteJobTitleModalBox({ actionType, id, internarShipper, status, name }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.actionType = actionType;
                    this.resetSelectedJobTitle();
                    this.fillSelectedJobTitle({ id, companyId: this.company._id, internarShipper, status, name });
                    await window.UIkit.modal('#delete-job-title-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showJobListModalBox({ officeId }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.modalBoxLoading = true;
                    this.selectedOffice.id = officeId;
                    this.selectedJobRole = null;
                    await window.UIkit.modal('#job-title-list-modal-box').show();
                    await this.setJobListOptions({ officeId });
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modalBoxLoading = false;
            }
        },
        async showQrCodeModalBox() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.resetQrCode();
                    this.fillQrCode({ companyId: this.company._id, warehouseId: this.selectedOffice.id, jobRole: this.selectedJobRole });
                    await window.UIkit.modal('#job-title-list-modal-box').hide();
                    await window.UIkit.modal('#qr-code-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showCompanyOfficeLogModalBox({ officeId }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.modalBoxLoading = true;
                    await this.getCompanyOfficeLog({ company_office_id: officeId });
                    await window.UIkit.modal('#company-office-log-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modalBoxLoading = false;
            }
        },
        async showDeleteCompanyOfficeModalBox({ id, type, name, code, domicileCityId, address, longitude, latitude, radius, notes, status }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.resetSelectedOffice();
                    this.fillSelectedOffice({ id, companyId: null, type, name, code, domicileCityId, address, longitude, latitude, radius, notes, status });
                    await window.UIkit.modal('#delete-company-office-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showCreateOrEditOfficeModalBox({ actionType, id, companyId, type, name, code, domicileCityId, address, longitude, latitude, radius, notes, status }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.actionType = actionType;
                    this.hideDropdown();
                    this.resetSelectedOffice();
                    if (actionType === 'createCompanyOffice') {
                        this.fillSelectedOffice({
                            id: null,
                            companyId,
                            type: null,
                            name: null,
                            code: null,
                            domicileCityId: null,
                            address: null,
                            longitude: null,
                            latitude: null,
                            radius: null,
                            notes: null,
                            status: null
                        });
                    } else if (actionType === 'editCompanyOffice') {
                        this.fillSelectedOffice({
                            id,
                            companyId,
                            type,
                            name,
                            code,
                            domicileCityId,
                            address,
                            longitude,
                            latitude,
                            radius,
                            notes,
                            status
                        });
                    }
                    await window.UIkit.modal('#create-or-edit-office-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showDownloadTemplateModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    await window.UIkit.modal('#download-template-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showUploadTemplateModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    await window.UIkit.modal('#upload-template-modal-box').show();
                } else {
                    this.internetConnection = false; 
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showSettingModalBox() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true; 
                    await window.UIkit.modal('#setting-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showErrorLogModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.companyTemplateErrorLogLoading = true;
                    await window.UIkit.modal('#error-log-modal-box').show();
                    await this.setCompanyTemplateErrorLogList();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companyTemplateErrorLogLoading = false;
            }
        },
        goToOvertimeReportViewSetting({ companyId }) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                const { href } = this.$router.resolve({ name: 'SettingOvertimeReportView', params: { data: companyId } });                
                window.open(href, '_blank');
            } else {
                this.internetConnection = false;
            }
        },
        officeType({ companyId }) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.$router.push(`/admin/mitra/company/office_type/${companyId}`);
            } else {
                this.internetConnection = false;
            }
        },
        workersRequest({ companyId }) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.$router.push(`/admin/mitra/company/workers_request/${companyId}`);
            } else {
                this.internetConnection = false;
            }
        },
        roleSalaryCompany({ companyId, officeId }) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.hideDropdown();
                this.$router.push(`/admin/mitra/company/role_salary/${companyId}/${officeId}`);
            } else {
                this.internetConnection = false;
            }
        },
        assignMitra({ companyId, officeId }) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.hideDropdown();
                this.$router.push(`/admin/mitra/company/assign_mitra/${companyId}/${officeId}`);
            } else {
                this.internetConnection = false;
            }
        },
        attendanceCompany({ companyId, officeId }) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.$router.push(`/admin/mitra/company/attendance/${companyId}/${officeId}`);
            } else {
                this.internetConnection = false;
            }
        },
        async createOrEditJobTitle() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    if (this.actionType === 'createJobTitle') {
                        await this.doCreateJobTitle();
                    } else if (this.actionType === 'editJobTitle') {
                        await this.doEditJobTitle();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async createOrEditCompanyOffice() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    if (this.actionType === 'createCompanyOffice') {
                        await this.doCreateCompanyOffice();
                    } else if (this.actionType === 'editCompanyOffice') {
                        await this.doEditCompanyOffice();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doCreateJobTitle() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    const validate = await this.$validator.validate('selectedJobTitle.*');
                    if (!validate || this.$validator.errors.any('selectedJobTitle')) return;

                    this.modalBoxButtonLoading = true;

                    const payloads = {
                        company_id: this.company._id,
                        internal_shipper: this.selectedJobTitle.internal_shipper,
                        status: null,
                        name: this.selectedJobTitle.name
                    };
                    if (this.selectedJobTitle.status && this.selectedJobTitle.status.value !== null) {
                        payloads.status = this.selectedJobTitle.status.value;
                    }

                    const response = await this.createCompanyRole(payloads);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#create-or-edit-job-title-modal-box').hide();
                        this.resetSelectedJobTitle();
                        notificationSuccess('Company role created successfully!');
                        this.jobTitleTableLoading = true;
                        await this.setCompany();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modalBoxButtonLoading = false;
                this.jobTitleTableLoading = false;
            }
        },
        async doEditJobTitle() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    const validate = await this.$validator.validate('selectedJobTitle.*');
                    if (!validate || this.$validator.errors.any('selectedJobTitle')) return;

                    this.modalBoxButtonLoading = true;

                    const payloads = {
                        id: this.selectedJobTitle.id,
                        company_id: this.company._id,
                        internal_shipper: this.selectedJobTitle.internal_shipper,
                        status: null,
                        name: this.selectedJobTitle.name
                    };
                    if (this.selectedJobTitle.status && this.selectedJobTitle.status.value !== null) {
                        payloads.status = this.selectedJobTitle.status.value;
                    }

                    const response = await this.updateCompanyRole(payloads);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#create-or-edit-job-title-modal-box').hide();
                        this.resetSelectedJobTitle();
                        notificationSuccess('Company role updated successfully!');
                        this.jobTitleTableLoading = true;
                        await this.setCompany();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modalBoxButtonLoading = false;
                this.jobTitleTableLoading = false;
            }
        },
        async doDeleteJobTitle() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    this.modalBoxButtonLoading = true;

                    const response = await this.deleteCompanyRole(this.selectedJobTitle.id);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#delete-job-title-modal-box').hide();
                        this.resetSelectedJobTitle();
                        this.$validator.reset();
                        notificationSuccess('Company role deleted successfully!');
                        this.jobTitleTableLoading = true;
                        await this.setCompany();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modalBoxButtonLoading = false;
                this.jobTitleTableLoading = false;
            }
        },
        async doCreateCompanyOffice() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    const validate = await this.$validator.validate('selectedOffice.*');
                    if (!validate || this.$validator.errors.any('selectedOffice')) return;

                    this.modalBoxButtonLoading = true;

                    const payloads = {
                        company_id: this.selectedOffice.company_id,
                        type_id: null,
                        status: null,
                        name: this.selectedOffice.name,
                        code: this.selectedOffice.code,
                        domicile_city_id: null,
                        notes: this.selectedOffice.notes,
                        address: this.selectedOffice.address,
                        radius: this.selectedOffice.radius,
                        geoloc: {
                            lat: this.selectedOffice.geoloc.lat ? this.selectedOffice.geoloc.lat : 0,
                            lng: this.selectedOffice.geoloc.lng ? this.selectedOffice.geoloc.lng : 0
                        }
                    };

                    if (this.selectedOffice.type_id !== null && this.selectedOffice.type_id.value) {
                        const typeId = this.selectedOffice.type_id.value;
                        payloads.type_id = typeId;
                    }
                    if (this.selectedOffice.status !== null && this.selectedOffice.status.value !== null) {
                        const status = this.selectedOffice.status.value;
                        payloads.status = status;
                    }
                    if (this.selectedOffice.domicile_city_id !== null && this.selectedOffice.domicile_city_id.value) {
                        const domicileCityId = this.selectedOffice.domicile_city_id.value;
                        payloads.domicile_city_id = domicileCityId;
                    }

                    const response = await this.createProperty(payloads);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#create-or-edit-office-modal-box').hide();
                        this.resetSelectedOffice();
                        notificationSuccess('Company office created successfully!');
                        this.companyOfficeTableLoading = true;
                        await this.setCompany();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modalBoxButtonLoading = false;
                this.companyOfficeTableLoading = false;
            }
        },
        async doEditCompanyOffice() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    const validate = await this.$validator.validate('selectedOffice.*');
                    if (!validate || this.$validator.errors.any('selectedOffice')) return;

                    this.modalBoxButtonLoading = true;

                    const payloads = {
                        id: this.selectedOffice.id,
                        company_id: this.selectedOffice.company_id,
                        type_id: null,
                        status: null,
                        name: this.selectedOffice.name,
                        code: this.selectedOffice.code,
                        domicile_city_id: null,
                        notes: this.selectedOffice.notes,
                        address: this.selectedOffice.address,
                        radius: this.selectedOffice.radius,
                        geoloc: {
                            lat: this.selectedOffice.geoloc.lat ? this.selectedOffice.geoloc.lat : 0,
                            lng: this.selectedOffice.geoloc.lng ? this.selectedOffice.geoloc.lng : 0
                        }
                    };

                    if (this.selectedOffice.type_id !== null && this.selectedOffice.type_id.value) {
                        const typeId = this.selectedOffice.type_id.value;
                        payloads.type_id = typeId;
                    }
                    if (this.selectedOffice.status !== null && this.selectedOffice.status.value !== null) {
                        const status = this.selectedOffice.status.value;
                        payloads.status = status;
                    }
                    if (this.selectedOffice.domicile_city_id !== null && this.selectedOffice.domicile_city_id.value) {
                        const domicileCityId = this.selectedOffice.domicile_city_id.value;
                        payloads.domicile_city_id = domicileCityId;
                    }

                    const response = await this.updateProperty(payloads);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#create-or-edit-office-modal-box').hide();
                        this.resetSelectedOffice();
                        notificationSuccess('Company office updated successfully!');
                        this.companyOfficeTableLoading = true;
                        await this.setCompany();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modalBoxButtonLoading = false;
                this.companyOfficeTableLoading = false;
            }
        },
        async doDeleteCompanyOffice() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;

                    this.modalBoxButtonLoading = true;

                    const response = await this.deleteProperty(this.selectedOffice.id);
                    if (response && response.status === 'OK') {
                        await window.UIkit.modal('#delete-company-office-modal-box').hide();
                        this.resetSelectedOffice();
                        notificationSuccess('Property deleted successfully!');
                        this.companyOfficeTableLoading = true;
                        await this.setCompany();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.modalBoxButtonLoading = false;
                this.companyOfficeTableLoading = false;
            }
        },
        onCopy(e) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.hideDropdown();
                notificationSuccess('Copied to clipboard: ' + e.text);
            } else {
                this.internetConnection = false;
            }
        },
        async hideCompanyOfficeLogModalBox() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await window.UIkit.modal('#company-office-log-modal-box').hide();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        hideDropdown() {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
        },
        fillSelectedJobTitle({ id, companyId, internarShipper, status, name }) {
            this.selectedJobTitle.id = id;
            this.selectedJobTitle.company_id = companyId;
            this.selectedJobTitle.internal_shipper = internarShipper;
            this.selectedJobTitle.name = name;

            if (status !== null) {
                const findJobTitle = this.statusOptions.find((item) => item.value === status);
                this.selectedJobTitle.status = findJobTitle;
            }
        },
        fillQrCode({ companyId, warehouseId, jobRole }) {
            this.qrCode.company_id = companyId;
            this.qrCode.warehouse_id = warehouseId;

            if (jobRole && jobRole.name) {
                this.qrCode.title = jobRole.name;
            }
            if (jobRole && jobRole._id) {
                this.qrCode.role_id = jobRole._id;
            }
        },
        fillSelectedOffice({ id, companyId, type, name, code, domicileCityId, address, longitude, latitude, radius, notes, status }) {
            this.selectedOffice.id = id;
            this.selectedOffice.company_id = companyId;
            this.selectedOffice.name = name;
            this.selectedOffice.code = code;
            this.selectedOffice.domicile_city_id = domicileCityId;
            this.selectedOffice.notes = notes;
            this.selectedOffice.address = address;
            this.selectedOffice.radius = radius;
            this.selectedOffice.geoloc.lat = latitude;
            this.selectedOffice.geoloc.lng = longitude;

            if (domicileCityId) {
                const domicileCity = { value: domicileCityId._id, name: domicileCityId.name };
                this.selectedOffice.domicile_city_id = domicileCity;
            }
            if (type !== null) {
                const officeType = { value: type._id, name: type.code };
                this.selectedOffice.type_id = officeType;
            }
            if (status !== null) {
                const findStatus = this.statusOptions.find((item) => item.value === status);
                this.selectedOffice.status = findStatus;
            }
        },
        resetSelectedJobTitle() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.selectedJobTitle.id = null;
                this.selectedJobTitle.company_id = null;
                this.selectedJobTitle.internal_shipper = false;
                this.selectedJobTitle.status = null;
                this.selectedJobTitle.name = '';
                this.$validator.reset();
            } else {
                this.internetConnection = false;
            }
        },
        resetQrCode() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.qrCode.title = null;
                this.qrCode.company_id = null;
                this.qrCode.warehouse_id = null;
                this.qrCode.role_id = null;
            } else {
                this.internetConnection = false;
            }
        },
        resetSelectedOffice() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.selectedOffice.id = null;
                this.selectedOffice.company_id = null;
                this.selectedOffice.type_id = null;
                this.selectedOffice.status = null;
                this.selectedOffice.name = null;
                this.selectedOffice.code = null;
                this.selectedOffice.domicile_city_id = null;
                this.selectedOffice.notes = null;
                this.selectedOffice.address = null;
                this.selectedOffice.radius = null;
                this.selectedOffice.geoloc.lat = null;
                this.selectedOffice.geoloc.lng = null;
                this.$validator.reset();
            } else {
                this.internetConnection = false;
            }
        },
        statusColor({ status }){
            switch (status) {
            case 0:
                return "background-color: #FFFFFF; color: #999999";
            case 1:
                return "background-color: #FEBE10; color: #FFFFFF";
            case 2:
                return "background-color: #2DD8D3; color: #FFFFFF";
            case 3:
                return "background-color: #0275D8; color: #FFFFFF";
            case 4:
                return "background-color: #00cc00; color: #FFFFFF";
            case 5:
                return "background-color: #FF3737; color: #FFFFFF";
            case 6:
                return "background-color: #C4C4C4; color: #FFFFFF";
            default: 
                return "-";
            }
        },
        statusLabel({ status }){
            switch (status) {
            case 0:
                return "Incoming";
            case 1:
                return "On Progress";
            case 2:
                return "Waiting Contract";
            case 3:
                return "Contract";
            case 4:
                return "Active";
            case 5:
                return "Canceled";
            case 6:
                return "Inactive";
            default: 
                return "-";
            }
        },
        stateLabel({ state }){
            switch (state) {
            case 0:
                return 'Inactive';
            case 1:
                return 'Active';
            default:
                return '-';
            }
        },
        typeLabel({ id }){
            if (!id) {
                return '-';
            }

            for (const type of this.officeTypeOptions) {
                if (id == type.value) {
                    return type.name;
                }
            }
        },
        domicileLabel({ id }) {
            if (!id) {
                return '-';
            }

            for (const city of this.domicileOptions) {
                if (id == city.value) {
                    return city.name;
                }
            }
        },
        picLabel({ datas }){
            let label = '';
            for (const data of datas) {
                if (label.length < 1) {
                    label += data.fullname;
                } else {
                    label += `, ${data.fullname}`;
                }
            }
            return label;
        },
        formatDatetime({ date }) {
            if (!date) return '-';
            return formatter.datetimeComplete(date);
        },
        preview({ string }) {
            if (string == null) {
                return '-';
            } else {
                if (string.length > 26) {
                    return string.substring(0,26)+'...';
                } else {
                    return string;
                }
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        async hideDownloadTemplateModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    await window.UIkit.modal('#download-template-modal-box').hide();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideUploadTemplateModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    await window.UIkit.modal('#upload-template-modal-box').hide();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideErrorLogModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    await window.UIkit.modal('#error-log-modal-box').hide();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doDownloadJobTitleTemplate() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.downloadJobTitleTemplateLoading = true;
                    const response = await this.downloadCompanyRoleTemplate(this.$route.params.company_id);
                    if (response && response.status === 'OK' && response.result) {
                        const result = response.result || null;
                        const fileName = result.file_name || 'job_title.xlsx';
                        const data = result.data || [];
                        excelDownloader(data, fileName);
                        notificationSuccess('Template downloaded successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadJobTitleTemplateLoading = false;
            }
        },
        async doDownloadOfficeTypeTemplate() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.downloadOfficeTypeTemplateLoading = true;
                    const response = await this.downloadOfficeTypeTemplate(this.$route.params.company_id);
                    if (response && response.status === 'OK' && response.result) {
                        const result = response.result || null;
                        const fileName = result.file_name || 'office_type.xlsx';
                        const data = result.data || [];
                        excelDownloader(data, fileName);
                        notificationSuccess('Template downloaded successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadOfficeTypeTemplateLoading = false;
            }
        },
        async doDownloadOfficeTemplate() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.downloadCreateOfficeTemplateLoading = true;
                    const [response, cities] = await Promise.all([
                        this.downloadOfficeTemplate(this.$route.params.company_id),
                        this.getCity()
                    ]);                    
                    if (response && response.status === 'OK' && response.result) {
                        const result = response.result;
                        const fileName = result && result.file_name || 'create_office.xlsx';
                        const sheetName = 'bulk upload create office';
                        const data = result && result.data || [];
                        const additionalExcelData = [];

                        if (cities && cities.length > 0) {
                            const cityList = [];
                            cities.forEach((city) => {
                                cityList.push({
                                    'DATA KABUPATEN/KOTA': city.name
                                });
                            });

                            additionalExcelData.push({
                                sheet_name: 'daftar kabupaten-kota',
                                data: cityList
                            });
                        }

                        excelDownloaderWithAdditionalSheet(data, sheetName, additionalExcelData, fileName);
                        notificationSuccess('Template downloaded successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadCreateOfficeTemplateLoading = false;
            }
        },
        async doDownloadSalarySetupTemplate() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.downloadSalarySetupTemplateLoading = true;
                    const response = await this.downloadSalarySetupTemplate(this.$route.params.company_id);
                    if (response && response.status === 'OK' && response.result) {
                        const result = response.result;
                        const data = result && result.data || [];
                        const fileName = result && result.file_name || 'update_salary_setup.xlsx';
                        const sheetName = result && result.sheet_name || 'bulk upload salary setup';
                        if (result.additional_data && result.additional_data.length > 0) {
                            const additionalDataExcel = result.additional_data;
                            excelDownloaderWithAdditionalSheet(data, sheetName, additionalDataExcel, fileName);
                        } else {
                            excelDownloader(data, fileName);
                        }
                        notificationSuccess('Template downloaded successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadSalarySetupTemplateLoading = false;
            }
        },
        async doDownloadCreateWorkerRequestTemplate() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.downloadCreateWorkerRequestTemplateLoading = true;
                    const response = await this.downloadWorkerRequestTemplate(this.$route.params.company_id);
                    if (response && response.status === 'OK' && response.result) {
                        const result = response.result;
                        const data = result && result.data || [];
                        const fileName = result && result.file_name || 'create_worker_request.xlsx';
                        const sheetName = result && result.sheet_name || 'bulk upload worker request';
                        if (result.additional_data && result.additional_data.length > 0) {
                            const additionalDataExcel = result.additional_data;
                            excelDownloaderWithAdditionalSheet(data, sheetName, additionalDataExcel, fileName);
                        } else {
                            excelDownloader(data, fileName);
                        }
                        notificationSuccess('Template downloaded successfully');
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadCreateWorkerRequestTemplateLoading = false;
            }
        },
        triggerUploadJobTitleTemplate() {
            if (navigator.onLine) {
                this.internetConnection = true;
                this.$refs.uploadJobTitleTemplate.click();
            } else {
                this.internetConnection = false;
            }
        },
        async doUploadJobTitleTemplate(event) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;

                    await window.UIkit.modal('#upload-template-modal-box').hide();
                    await window.UIkit.modal('#upload-loader-modal-box').show();
                    this.uploadTemplateLoading = true;

                    if (event && event.target && event.target.files[0]) {
                        const filePath = event.target.files[0];
                        const formData = new FormData();

                        formData.append('company_id', this.$route.params.company_id);
                        formData.append('excel', filePath);

                        const response = await this.uploadJobTitleTemplate(formData);
                        if (response && response.status == 'OK') {
                            formData.delete('company_id');
                            formData.delete('excel');
                            event.target.value = null;

                            this.jobTitleTableLoading = true;
                            await this.setCompany();

                            notificationSuccess('Upload was successful');
                        }
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#upload-loader-modal-box').hide();
                this.uploadTemplateLoading = false;
                this.jobTitleTableLoading = false;
                await window.UIkit.modal('#upload-template-modal-box').show();
            }
        },
        triggerUploadOfficeTypeTemplate() {
            if (navigator.onLine) {
                this.internetConnection = true;
                this.$refs.uploadOfficeTypeTemplate.click();
            } else {
                this.internetConnection = false;
            }
        },
        async doUploadOfficeTypeTemplate(event) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;

                    await window.UIkit.modal('#upload-template-modal-box').hide();
                    await window.UIkit.modal('#upload-loader-modal-box').show();
                    this.uploadTemplateLoading = true;

                    if (event && event.target && event.target.files[0]) {
                        const filePath = event.target.files[0];
                        const formData = new FormData();

                        formData.append('company_id', this.$route.params.company_id);
                        formData.append('excel', filePath);

                        const response = await this.uploadOfficeTypeTemplate(formData);
                        if (response && response.status == 'OK') {
                            formData.delete('company_id');
                            formData.delete('excel');
                            event.target.value = null;

                            notificationSuccess('Upload was successful');
                        }
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#upload-loader-modal-box').hide();
                this.uploadTemplateLoading = false;
                await window.UIkit.modal('#upload-template-modal-box').show();
            }
        },
        triggerUploadCreateOfficeTemplate() {
            if (navigator.onLine) {
                this.internetConnection = true;
                this.$refs.uploadCreateOfficeTemplate.click();
            } else {
                this.internetConnection = false;
            }
        },
        async doUploadCreateOfficeTemplate(event) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;

                    await window.UIkit.modal('#upload-template-modal-box').hide();
                    await window.UIkit.modal('#upload-loader-modal-box').show();
                    this.uploadTemplateLoading = true;

                    if (event && event.target && event.target.files[0]) {
                        const filePath = event.target.files[0];
                        const formData = new FormData();

                        formData.append('company_id', this.$route.params.company_id);
                        formData.append('excel', filePath);

                        const response = await this.uploadCreateOfficeTemplate(formData);
                        if (response && response.status == 'OK') {
                            formData.delete('company_id');
                            formData.delete('excel');
                            event.target.value = null;

                            this.companyOfficeTableLoading = true;
                            await this.setCompany();

                            notificationSuccess('Upload was successful');
                        }
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#upload-loader-modal-box').hide();
                this.uploadTemplateLoading = false;
                this.companyOfficeTableLoading = false;
                await window.UIkit.modal('#upload-template-modal-box').show();
            }
        },
        triggerUploadSalarySetupTemplate() {
            if (navigator.onLine) {
                this.internetConnection = true;
                this.$refs.uploadSalarySetupTemplate.click();
            } else {
                this.internetConnection = false;
            }
        },
        async doUploadSalarySetupTemplate(event) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;

                    await window.UIkit.modal('#upload-template-modal-box').hide();
                    await window.UIkit.modal('#upload-loader-modal-box').show();
                    this.uploadTemplateLoading = true;

                    if (event && event.target && event.target.files[0]) {
                        const filePath = event.target.files[0];
                        const formData = new FormData();

                        formData.append('company_id', this.$route.params.company_id);
                        formData.append('excel', filePath);

                        const response = await this.uploadSalarySetupTemplate(formData);
                        if (response && response.status == 'OK') {
                            formData.delete('company_id');
                            formData.delete('excel');
                            event.target.value = null;

                            this.companyOfficeTableLoading = true;
                            await this.setCompany();

                            notificationSuccess('Upload was successful');
                        }
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#upload-loader-modal-box').hide();
                this.uploadTemplateLoading = false;
                this.companyOfficeTableLoading = false;
                await window.UIkit.modal('#upload-template-modal-box').show();
            }
        },
        triggerUploadWorkerRequestTemplate() {
            if (navigator.onLine) {
                this.internetConnection = true;
                this.$refs.uploadWorkerRequestTemplate.click();
            } else {
                this.internetConnection = false;
            }
        },
        async doUploadWorkerRequestTemplate(event) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;

                    await window.UIkit.modal('#upload-template-modal-box').hide();
                    await window.UIkit.modal('#upload-loader-modal-box').show();
                    this.uploadTemplateLoading = true;

                    if (event && event.target && event.target.files[0]) {
                        const filePath = event.target.files[0];
                        const formData = new FormData();

                        formData.append('company_id', this.$route.params.company_id);
                        formData.append('excel', filePath);

                        const response = await this.uploadWorkerRequestTemplate(formData);
                        if (response && response.status == 'OK') {
                            formData.delete('company_id');
                            formData.delete('excel');
                            event.target.value = null;

                            this.companyOfficeTableLoading = true;
                            await this.setCompany();

                            notificationSuccess('Upload was successful');
                        }
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#upload-loader-modal-box').hide();
                this.uploadTemplateLoading = false;
                this.companyOfficeTableLoading = false;
                await window.UIkit.modal('#upload-template-modal-box').show();
            }
        },
        async setCompanyTemplateErrorLogList() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;

                    const response = await this.getCompanyTemplateErrorLogList(this.$route.params.company_id);
                    if (response && response.status === 'OK' && response.result) {
                        const result = response.result || null;

                        this.companyTemplateErrorLogList.jobTitleErrorLog = result.job_title_error_log || null;
                        this.companyTemplateErrorLogList.officeTypeErrorLog = result.office_type_error_log || null;
                        this.companyTemplateErrorLogList.companyOfficeErrorLog = result.company_office_error_log || null;
                        this.companyTemplateErrorLogList.salaryOfficeErrorLog = result.salary_setup_error_log || null;
                        this.companyTemplateErrorLogList.workerRequestErrorLog = result.worker_request_error_log || null;
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        dateWithSlash(date) {
            return formatter.dateWithSlash(date);
        },
        async showJobTitleErrorLogDetailModalBox(datas) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.companyTemplateErrorLogLoading = true;
                    this.selectedJobTitleIncorrectDatas = datas;
                    await window.UIkit.modal('#error-log-modal-box').hide();
                    await window.UIkit.modal('#job-title-error-log-detail-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companyTemplateErrorLogLoading = false;
            }
        },
        async hideJobTitleErrorLogDetailModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    await window.UIkit.modal('#job-title-error-log-detail-modal-box').hide();
                    this.selectedJobTitleIncorrectDatas = [];
                    await this.showErrorLogModalBox();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async downloadJobTitleErrorLogData(datas) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.downloadJobTitleErrorLogLoading = true;

                    if (!Array.isArray(datas)) throw new Error('Datas must be array');
                    if (datas.length < 1) throw new Error('Datas not found');

                    const excelData = [];
                    datas.forEach((data) => {
                        excelData.push({
                            '*Company': data['*Company'] || null,
                            '*Job Title ID': data['*Job Title ID'] || null,
                            '*Job Name': data['*Job Name'] || null,
                            '*Job Status': data['*Job Status'] || null,
                            '*Internal Role': data['*Internal Role'] || null,
                            'Error Status': data && data.status || null
                        });
                    });
 
                    excelDownloader(excelData, 'job_title_error_log.xlsx');
                    notificationSuccess('Download successfully');
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadJobTitleErrorLogLoading = false;
            }
        },
        async showOfficeTypeErrorLogDetailModalBox(datas) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.companyTemplateErrorLogLoading = true;
                    this.selectedOfficeTypeIncorrectDatas = datas;
                    await window.UIkit.modal('#error-log-modal-box').hide();
                    await window.UIkit.modal('#office-type-error-log-detail-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companyTemplateErrorLogLoading = false;
            }
        },
        async hideOfficeTypeErrorLogDetailModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    await window.UIkit.modal('#office-type-error-log-detail-modal-box').hide();
                    this.selectedOfficeTypeIncorrectDatas = [];
                    await this.showErrorLogModalBox();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companyTemplateErrorLogLoading = false;
            }
        },
        async downloadOfficeTypeErrorLogData(datas) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.downloadOfficeTypeErrorLogLoading = true;

                    if (!Array.isArray(datas)) throw new Error('Datas must be array');
                    if (datas.length < 1) throw new Error('Datas not found');

                    const excelData = [];
                    datas.forEach((data) => {
                        excelData.push({
                            '*Company': data['*Company'] || null,
                            '*Office Type ID': data['*Office Type ID'] || null,
                            '*Office Type Code': data['*Office Type Code'] || null,
                            '*Description': data['*Description'] || null,
                            '*Status': data['*Status'] || null,
                            'Error Status': data && data.status || null
                        });
                    });

                    excelDownloader(excelData, 'office_type_error_log.xlsx');
                    notificationSuccess('Download successfully');
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadOfficeTypeErrorLogLoading = false;
            }
        },
        async showCreateOfficeErrorLogDetailModalBox(datas) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.companyTemplateErrorLogLoading = true;
                    this.selectedCreateOfficeIncorrectDatas = datas;
                    await window.UIkit.modal('#error-log-modal-box').hide();
                    await window.UIkit.modal('#create-office-error-log-detail-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companyTemplateErrorLogLoading = false;
            }
        },
        async hideCreateOfficeErrorLogDetailModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.companyTemplateErrorLogLoading = true;
                    await window.UIkit.modal('#create-office-error-log-detail-modal-box').hide();
                    this.selectedCreateOfficeIncorrectDatas = [];
                    await this.showErrorLogModalBox();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companyTemplateErrorLogLoading = false;
            }
        },
        async downloadCreateOfficeErrorLogData(datas) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.downloadCreateOfficeErrorLogLoading = true;

                    if (!Array.isArray(datas)) throw new Error('Datas must be array');
                    if (datas.length < 1) throw new Error('Datas not found');

                    const excelData = [];
                    datas.forEach((data) => {
                        excelData.push({
                            '*Company': data['*Company'] || null,
                            '*Office Type': data['*Office Type'] || null,
                            '*Office ID': data['*Office ID'] || null,
                            '*Office Name': data['*Office Name'] || null,
                            '*Office Code': data['*Office Code'] || null,
                            '*Office City': data['*Office City'] || null,
                            '*Office Address': data['*Office Address'] || null,
                            '*Coordinate Latitude': data['*Coordinate Latitude'] || null,
                            '*Coordinate Longitude': data['*Coordinate Longitude'] || null,
                            '*Attendance Radius': data['*Attendance Radius'] || null,
                            '*Office Notes': data['*Office Notes'] || null,
                            '*Office Status': data['*Office Status'] || null,
                            'Error Status': data && data.status || null
                        });
                    });

                    excelDownloader(excelData, 'create_office_error_log.xlsx');
                    notificationSuccess('Download successfully');
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadCreateOfficeErrorLogLoading = false;
            }
        },
        async showSalarySettingErrorLogDetailModalBox(datas) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.companyTemplateErrorLogLoading = true;
                    this.selectedSalarySettingIncorrectDatas = datas;
                    await window.UIkit.modal('#error-log-modal-box').hide();
                    await window.UIkit.modal('#salary-setup-error-log-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companyTemplateErrorLogLoading = false;
            }
        },
        async hideSalarySettingErrorLogModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.companyTemplateErrorLogLoading = true;
                    await window.UIkit.modal('#salary-setup-error-log-modal-box').hide();
                    this.selectedSalarySettingIncorrectDatas = [];
                    await this.showErrorLogModalBox();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companyTemplateErrorLogLoading = false;
            }
        },
        async downloadSalarySettingErrorLogData(datas) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.downloadSalarySettingErrorLogLoading = true;

                    if (!Array.isArray(datas)) throw new Error('Datas must be array');
                    if (datas.length < 1) throw new Error('Datas not found');

                    const excelData = [];
                    datas.forEach((data) => {
                        excelData.push({
                            'Company*': data['Company*'] || null,
                            'Salary Setup ID*': data['Salary Setup ID*'] || null,
                            'Job Title Name*': data['Job Title Name*'] || null,
                            'Job Title ID*': data['Job Title ID*'] || null,
                            'Office Name*': data['Office Name*'] || null,
                            'Office ID*': data['Office ID*'] || null,
                            'Fee Normal (Rp)*': data['Fee Normal (Rp)*'] || null,
                            'Fee Normal Unit*': data['Fee Normal Unit*'] || null,
                            'Fee Overtime Hourly (Rp)*': data['Fee Overtime Hourly (Rp)*'] || null,
                            'Normal Workhours (Hours)*': data['Normal Workhours (Hours)*'] || null,
                            'Breaktime (Hours)*': data['Breaktime (Hours)*'] || null,
                            'Workhour Include Break*': data['Workhour Include Break*'] || null,
                            'Error Status': data && data.status || null
                        });
                    });

                    excelDownloader(excelData, 'update_salary_setting_error_log.xlsx');
                    notificationSuccess('Download successfully');
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadSalarySettingErrorLogLoading = false;
            }
        },
        async showWorkerRequestErrorLogDetailModalBox(datas) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.companyTemplateErrorLogLoading = true;
                    this.selectedWorkerRequestIncorrectDatas = datas;
                    await window.UIkit.modal('#error-log-modal-box').hide();
                    await window.UIkit.modal('#worker-request-error-log-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companyTemplateErrorLogLoading = false;
            }
        },
        async hideWorkerRequestErrorLogModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.companyTemplateErrorLogLoading = true;
                    await window.UIkit.modal('#worker-request-error-log-modal-box').hide();
                    this.selectedWorkerRequestIncorrectDatas = [];
                    await this.showErrorLogModalBox();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.companyTemplateErrorLogLoading = false;
            }
        },
        async downloadWorkerRequestErrorLogData(datas) {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    this.downloadWorkerRequestErrorLogLoading = true;

                    if (!Array.isArray(datas)) throw new Error('Datas must be array');
                    if (datas.length < 1) throw new Error('Datas not found');

                    const excelData = [];
                    datas.forEach((data) => {
                        const startDate = data['Start Date (dd/mm/yyyy)*'] ? this.dateWithSlash(data['Start Date (dd/mm/yyyy)*']) : null;
                        const endDate = data['Due Date (dd/mm/yyyy)*'] ? this.dateWithSlash(data['Due Date (dd/mm/yyyy)*']) : null;
                        const workStart = data['Start Work (dd/mm/yyyy)*'] ? this.dateWithSlash(data['Start Work (dd/mm/yyyy)*']) : null;

                        excelData.push({
                            'Company*': data['Company*'] || null,
                            'Office Name*': data['Office Name*'] || null,
                            'Office ID*': data['Office ID*'] || null,
                            'Status*': 'In Progress',
                            'Job Title Name*': data['Job Title Name*'] || null,
                            'Job Title ID*': data['Job Title ID*'] || null,
                            'Start Date (dd/mm/yyyy)*': startDate,
                            'Due Date (dd/mm/yyyy)*': endDate,
                            'Start Work (dd/mm/yyyy)*': workStart,
                            'Manpower (Numbers)*': data['Manpower (Numbers)*'] || null,
                            'PIC ID (minimal 1)*': data['PIC ID (minimal 1)*'] || null,
                            'SEO Keyword*': data['SEO Keyword*'] || null,
                            'Error Status': data && data.status || null
                        });
                    });

                    excelDownloader(excelData, 'create_worker_request_error_log.xlsx');
                    notificationSuccess('Download successfully');
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadWorkerRequestErrorLogLoading = false;
            }
        },
    }
};
</script>

<style scoped>
.myrobin-primary-button-filled {
    background: #0ABAB5;
    color: #FFFFFF;
}

.myrobin-primary-button-outlined {
    border: 1px solid #0ABAB5;
    color: #0ABAB5;
}  

.myrobin-warning-button-filled {
    background: #EBB652;
    color: #FFFFFF;
}

.myrobin-button-white-filled {
    background: #FFFFFF;
}

.display-none {
    display: none;
}

.loader {
    border: 10px solid #66b2f4;
    border-radius: 50%;
    border-top: 10px solid #0275D8;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.text-underline {
    text-decoration: underline;
}
</style>
